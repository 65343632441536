<template>
  <dv-border-box-12 id="BaseInfo">
    <el-row >
      <el-col style="margin: 20px" :span="5">
        <el-statistic style="--el-statistic-content-font-size: 28px;" :value="data1" >
        <template #title>
          <div style="display: inline-flex; align-items: center">

            已发布文章
          </div>
        </template>
        </el-statistic>
        <div class="statistic-footer">
          <div class="footer-item">
            <span>比上个月</span>
            <span :class="data1_1>0?'red':'green'">
              {{ data1_1}}%
              <el-icon>
                <CaretTop v-if="data1_1>0" />
                <CaretBottom v-else />
              </el-icon>
            </span>
          </div>
        </div>

      </el-col>
      <el-col  style="margin: 20px" :span="2">
        <el-statistic style="--el-statistic-content-font-size: 28px;" title="文章分类" :value="data2" />
      </el-col>
      <el-col  style="margin: 20px" :span="4">
      缠论更新计划
        <el-progress type="dashboard" :percentage="percentage" :color="colors" />

      </el-col>

      <el-col  style="margin: 20px" :span="5">

      又拍云存储用量:

      <dv-percent-pond :config="config3" style="width:100px;height: 50px" />
      </el-col>

    </el-row>
  </dv-border-box-12>
</template>

<script setup>
import {ref} from "vue";
import {GetSiteInfoData} from "@/network/main";

const data1 = ref()
const data1_1=ref(0)
const data2 = ref()
const data3=ref([0])
const percentage=ref(0)
GetSiteInfoData('baseinfo').then(res=>{
  data1.value=res.data['article']
  data2.value=res.data['category']
  data3.value[0]=res.data['yp_usage']
  data1_1.value=res.data['article_increase']
  percentage.value=res.data['chanlun']

})
const colors = [
  { color: '#f56c6c', percentage: 20 },
  { color: '#e6a23c', percentage: 40 },
  { color: '#f5ac0d', percentage: 60 },
  { color: '#9db01d', percentage: 80 },
  { color: '#5cb87a', percentage: 100 },
]

const config3={
  value: data3.value,
  lineDash: [10, 2],
  localGradient: true,
  textColor:'#ffbf00',
}
</script>

<style scoped>
#BaseInfo{
  height: 200px;
}

.statistic-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  font-size: 12px;
  color: var(--el-text-color-regular);
  margin-top: 16px;
}

.statistic-footer .footer-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.statistic-footer .footer-item span:last-child {
  display: inline-flex;
  align-items: center;
  margin-left: 4px;
}

.green {
  color: var(--el-color-success);
}
.red {
  color: var(--el-color-error);
}
</style>