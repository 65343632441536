<template>

  <el-calendar ref="calendar" v-model="selected_date">
    <template #header="{ date }">
      <span>

        <el-button :icon="ArrowLeftBold"  round  @click="selectDate('prev-month')">
        </el-button>
        <el-button :icon="ArrowRightBold"  round @click="selectDate('next-month')">

        </el-button>
        <el-button size="small" @click="selectDate('today')">今天</el-button>
        <el-button circle
                   @click="EditPlan(selected_date.getFullYear()+'-'+selected_date.getMonth()+1+'-'+selected_date.getDate())"
                   :icon="Plus"
                   bg
                   text
        ></el-button>
      </span>
      <span>
                {{date }}</span>
      <span>
        <el-date-picker
            v-model="selected_date"
            type="month"
        />

    </span>
    </template>

    <template  #dateCell="{ data }">
      <div style="width: 100%" @click="EditPlan(data)">
        <span> {{ data.day.split('-').slice(2).join('-') }} </span>

        <span  :class="{ festival : isFestival(selected_date, data),'normalDay':!isFestival(selected_date, data) }" >
              {{ solarToLunar(selected_date, data) }}</span>
      </div>
      <div  v-for="i in items" :key="i.id" >
        <div  v-if="i.title==data.day+'个人中心日程计划'">
          <el-popover
              placement="right-start"
              trigger="hover"
              :content="i.content"
          >
            <template #reference>
              <el-tag   @click="EditPlan(data,i)" :color="tagColor" :type="tagType()" style="margin-top: 5px;">
          <span :style="{color:(i.is_checked?'#C0C0C0':'black')}">
              {{i.content}}
          </span>

              </el-tag>
            </template>
          </el-popover>

        </div>
      </div>
      <div style="height: 100%;" @click="EditPlan(data)" v-if="data.isSelected">
      </div>
    </template>
  </el-calendar>
  <el-dialog @close="CloseDialog" v-model="addPlanDialog" >
    <el-form :model="form">
      <el-form-item >
        <el-icon :size="22"><Calendar /></el-icon> {{ form.date }}
      </el-form-item>
      <el-form-item label="内容：" >
      <span style="display: inline-flex">
                    <el-icon :size="22" :color="form.is_checked?'green':''" @click="checkPlan">
                      <CircleCheck v-if="form.is_checked" />
                      <div class="schdo" v-else></div>
                    </el-icon>
        <el-input maxlength="20"      show-word-limit
                  v-model="form.content"
                  class="content_input"
                  placeholder="请输入内容"
                  @keydown.enter="inputSubmit"
                  autocomplete="off"  >

        </el-input>
              </span>

      </el-form-item>
      <el-form-item label="背景色：">
        <el-color-picker v-model="tagColor"  :predefine="predefineColors" />
      </el-form-item>

    </el-form>
    <p>
      <el-switch
          inactive-text="推迟一天："
          v-model="isPutOff"
          @change="submitPlan"
          style="--el-switch-on-color: #13ce66;"
      />
    </p>
    <template #footer>

      <el-button type="danger"  :icon="Delete" @click="deletePlan" />

      <el-button type="success"  :icon="Check"  @click="submitPlan" />
    </template>
  </el-dialog>
</template>

<script setup>
import {ref,reactive,watch } from "vue";
import {AddSchedule, DeletePlan, GetSchedule,UpdateSchedule} from "@/network/article";
import {ArrowLeftBold, ArrowRightBold,Delete,Check,Plus} from  '@element-plus/icons-vue'
import { ElMessage,ElMessageBox } from 'element-plus'
import GetCalendar from "@/composables/calendar"
const addPlanDialog= ref(false)

const selected_date= ref(new Date())       //日期双向绑定
const form = reactive({
  content: '',
  title: '',
  article_category:4,//文章分类的id（日程计划=4）
  is_checked:false,//是否完成
  schedule_date:selected_date,//日程计划归属日期
})
const isPutOff=ref(false)
const id=ref(0)
const items=ref()
const inputSubmit=()=>{
  //防止重复提交
  if(addPlanDialog.value){
    submitPlan()
  }
}
const calendar= ref()
//前端逻辑
const CloseDialog=()=>{
  addPlanDialog.value=false
  form.content=''
  form.title=''
  form.is_checked=false
  id.value=0
}
//随机选择标签类型
const tagType=()=>{
  const types = ['success', 'warning','info', 'danger']
  return types[Math.floor(Math.random() * types.length)]
}
//标签颜色
const tagColor=ref()
const predefineColors = ref([
  '#ff4500',
  '#ff8c00',
  '#ffd700',
  '#90ee90',
  '#00ced1',
  '#1e90ff',
  '#c71585',
  'rgba(255, 69, 0, 0.68)',
  'rgb(255, 120, 0)',
  'hsv(51, 100, 98)',
  'hsva(120, 40, 94, 0.5)',
  'hsl(181, 100%, 37%)',
  'hsla(209, 100%, 56%, 0.73)',
  '#c7158577',
])
const EditPlan = (data,item) => {

  addPlanDialog.value = true
  if(data.day) {
    form.date = data.day
  }
  else{
    form.date = data
  }
  if (item){
    form.content=item.content
    form.is_checked=item.is_checked
    id.value=item.id
  }

}
const checkPlan=()=>{
  form.is_checked=!form.is_checked
  if(form.content){
    submitPlan()
    addPlanDialog.value=false
  }


}
//日历头部自定义
const selectDate = (val) => {
  calendar.value.selectDate(val)
}
//服务器增删改查行为
const getSchedule = () => {
  const month=(selected_date.value.getMonth()+1)
  const year=selected_date.value.getFullYear()
  GetSchedule( JSON.stringify(month),JSON.stringify(year)).then(res => {
    items.value = res.data
  })
}
const submitPlan = () => {
  form.title= form.date+'个人中心日程计划'

  if(id.value==0){
      AddSchedule(form).then(() => {
        getSchedule()
        addPlanDialog.value = false
      })
    }
    else {
      if(isPutOff.value){
        let oldData= reactive({
          content: form.content+'(未完成已顺延)',
          title: form.title,
          article_category:4,//文章分类的id（日程计划=4）
          is_checked:true,//是否完成
          schedule_date:selected_date,//日程计划归属日期
        })
        AddSchedule(oldData).then(() => {
        })
          //form.date日期推迟一天
        const date=new Date(form.date)
        date.setDate(date.getDate()+1)
        let month=date.getMonth()+1
        if(month<10){
          month='0'+month
        }
        let day=date.getDate()
        if(day<10){
          day='0'+day
        }
        form.date=date.getFullYear()+'-'+month+'-'+day
        form.title=form.date+'个人中心日程计划'
        isPutOff.value=false
      }
      UpdateSchedule(form,id.value).then(() => {
        ElMessage.success(form.date+'  更新成功')
        getSchedule()
        addPlanDialog.value = false
      })
  }

}
const deletePlan=()=>{
  ElMessageBox.confirm(
      '确定要删除吗？',
      'Warning',
      {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }
  ).then(() => {
    if(id.value){

      DeletePlan(id.value).then(() => {
        ElMessage.warning(form.date+'  删除成功')
        getSchedule()
        addPlanDialog.value = false

      })
    }
    else {
      ElMessage.error('请先输入内容')
    }

  })

}
getSchedule()
//监听月份是否改变
watch(selected_date, (newValue, oldValue) => {
  if(newValue.getMonth()!=oldValue.getMonth()||newValue.getFullYear()!=oldValue.getFullYear()){
    getSchedule()
  }
})
// 是否节假日
const isFestival=(slotDate, slotData)=> {
  let solarDayArr = slotData.day.split('-');
  let lunarDay = GetCalendar.solar2lunar(solarDayArr[0], solarDayArr[1], solarDayArr[2])

  // 公历节日\农历节日\农历节气
  let festAndTerm = [];
  festAndTerm.push(lunarDay.festival == null ? '' : ' ' + lunarDay.festival)
  festAndTerm.push(lunarDay.lunarFestival == null ? '' : '' + lunarDay.lunarFestival)
  festAndTerm.push(lunarDay.Term == null ? '' : '' + lunarDay.Term)
  festAndTerm = festAndTerm.join('')

  return festAndTerm != ''
}
// 公历转农历
const  solarToLunar=(slotDate, slotData) => {
  let solarDayArr = slotData.day.split('-');
  let lunarDay = GetCalendar.solar2lunar(solarDayArr[0], solarDayArr[1], solarDayArr[2])

  // 农历日期
  let lunarMD = lunarDay.IMonthCn + lunarDay.IDayCn

  // 公历节日\农历节日\农历节气
  let festAndTerm = [];
  festAndTerm.push(lunarDay.festival == null ? '' : ' ' + lunarDay.festival)
  festAndTerm.push(lunarDay.lunarFestival == null ? '' : '' + lunarDay.lunarFestival)
  festAndTerm.push(lunarDay.Term == null ? '' : '' + lunarDay.Term)
  festAndTerm = festAndTerm.join('')

  return festAndTerm == '' ? lunarMD : festAndTerm
}

</script>

<style scoped>
.content_input{
  margin-left: 5px;
}
.schdo{
  border: 1px solid #A3A3A3;
  height: 18px;
  width: 18px;
  margin: 2px 0 0;
  border-radius: 10px;
}

</style>
<style>
.el-calendar-table td{
  border-color: #f9f9f9;
}
.festival{
  color: red ;
  font-size: xx-small;
  margin-left: 6px;

}
.normalDay{
  color: gray;
  font-size: xx-small;
  margin-left: 5px;
}
</style>