import { createStore } from 'vuex'

// 创建一个新的 store 实例
export const store = createStore({
    state () {
        return {
            token: '',
            refreshToken: '',
            userInfo: {},
            isLogin: false
        }
    },
    mutations: {
        setToken(state, token) {
            state.token = token
            localStorage.setItem('token', token)
        },
        setRefreshToken(state, refreshToken) {
            state.refreshToken = refreshToken
            localStorage.setItem('refreshToken', refreshToken)
        },
        setUserInfo(state, userInfo) {
            state.userInfo = userInfo
        },
        setIsLogin(state, isLogin) {
            state.isLogin = isLogin
        }
    },
    methods: {
        setToken(token) {
            this.commit('setToken', token)
        },
        setRefreshToken(refreshToken) {
            this.commit('setRefreshToken', refreshToken)
        },
        setUserInfo(userInfo) {
            this.commit('setUserInfo', userInfo)
        },
        setIsLogin(isLogin) {
            this.commit('setIsLogin', isLogin)
        }
    }

})


