<template>
  <p style="text-align: center;margin: 0 auto;font-weight: bold;margin-bottom: 5px;">
    <el-text type="warning" size="large">
      选股
    </el-text>
  </p>
  <el-row :gutter="16">
    <el-col :span="6" style="text-align: center">
      <el-statistic plain @click="dialogTableVisible = true"  title="股票总数" :value="baseinfo.total_stocks" />
      <div class="statistic-footer">
        <div class="footer-item">
          <span>整体涨跌幅</span>
          <span v-if="baseinfo.total_price_limit>0" class="red">
              {{ baseinfo.total_price_limit}}%
              <el-icon>
                <CaretTop />
              </el-icon>
            </span>
          <span v-if="baseinfo.total_price_limit<0" class="green">
              {{ baseinfo.total_price_limit}}%
              <el-icon>
                <CaretBottom  />
              </el-icon>
            </span>
        </div>
          <div class="footer-item">
          <span style="margin-left: 10px">平均涨跌幅度</span>
          <span v-if="baseinfo.total_price_limit>0" class="red">
              {{ (baseinfo.total_price_limit/(baseinfo.total_stocks-baseinfo.white_stocks)).toFixed(2) }}%
              <el-icon>
                <CaretTop />
              </el-icon>
            </span>
          <span v-if="baseinfo.total_price_limit<0" class="green">
              {{ (baseinfo.total_price_limit/(baseinfo.total_stocks-baseinfo.white_stocks)).toFixed(2)}}%
              <el-icon>
                <CaretBottom  />
              </el-icon>
            </span>
        </div>
      </div>
    </el-col>
    <el-col :span="3" style="text-align: center">
      <el-statistic title="成功" :value="baseinfo.success_stocks" />
      <div class="statistic-footer">
        <div class="footer-item">
          <span>最大涨幅</span>
          <span  class="red">
              {{ baseinfo.max_price_limit}}%
              <el-icon>
                  <CaretTop />

              </el-icon>
            </span>
        </div>
      </div>
    </el-col>
    <el-col :span="3 " style="text-align: center">
      <el-statistic title="失败" :value="baseinfo.fail_stocks" />
      <div class="statistic-footer">
        <div class="footer-item">
          <span>最大跌幅</span>
          <span  class="green">
              {{ baseinfo.min_price_limit}}%
              <el-icon>
                <CaretBottom />
              </el-icon>
            </span>
        </div>
      </div>
    </el-col>
    <el-col :span="6" style="text-align: center">
      <el-statistic  title="待定" :value="baseinfo.white_stocks" />
        <div class="statistic-footer">
            <div class="footer-item">

        <el >本月</el>
        <span v-if="baseinfo.this_month_limit>=0" class="red">
              {{ baseinfo.this_month_limit}}%
              <el-icon>
                <CaretTop />
              </el-icon>
            </span>
        <span v-if="baseinfo.this_month_limit<0" class="green">
              {{ baseinfo.this_month_limit}}%
              <el-icon>
                <CaretBottom  />
              </el-icon>
            </span>
            </div>
            <div class="footer-item">

            <span>上月</span>
                <span v-if="baseinfo.last_month_limit>=0" class="red">
              {{ baseinfo.last_month_limit}}%
              <el-icon>
                <CaretTop />
              </el-icon>
            </span>
                <span v-if="baseinfo.last_month_limit<0" class="green">
              {{ baseinfo.last_month_limit}}%
              <el-icon>
                <CaretBottom  />
              </el-icon>
            </span>
            </div>
                <div class="footer-item">
                <span >较上月</span>
                <span v-if="baseinfo.month_limit_rate>=0" class="red">
              {{ baseinfo.month_limit_rate}}%
              <el-icon>
                <CaretTop />
              </el-icon>
            </span>
                <span v-if="baseinfo.month_limit_rate<0" class="green">
              {{ baseinfo.month_limit_rate}}%
              <el-icon>
                <CaretBottom  />
              </el-icon>
            </span>
            </div>
        </div>

    </el-col>
    <el-col :span="6" style="text-align: center">

      <el-progress type="circle" :percentage="baseinfo.success_rate" :color="colors"
                   @mouseenter="showValue = true"
                   @mouseleave="showValue = false">
        <template #default="{ percentage }">
          <span v-if="showValue">{{baseinfo.success_stocks}}/{{baseinfo.success_stocks+baseinfo.fail_stocks}}</span>
          <span v-else class="percentage-value">{{ percentage }}%</span>
          <p class="percentage-label">成功率</p>
        </template>
      </el-progress>
      <el-progress
          v-if="baseinfo.actual_profit"
          type="circle"
          :percentage="baseinfo.actual_profit"
          :color="colors"
          @mouseenter="showValue1 = true"
          @mouseleave="showValue1 = false"
      >
        <template #default="{ percentage }">
      <span class="percentage-value">
        <el-icon size="22" color="#ffd700">
          <Coin />
        </el-icon>
        <span v-if="showValue1">{{100*percentage*0.01}}万元</span>
        <span v-else>{{ percentage }}%</span>
      </span>
          <p class="percentage-label">六年百万计划</p>
        </template>
      </el-progress>
    </el-col>
  </el-row>
    <el-divider></el-divider>
  <el-button type="primary" @click="handleExport">导出报表</el-button>
  <el-table @cell-click="handleCellClick"
            :row-class-name="tableRowClassName"
            @sort-change="handleSortChange"
            :data="results" style="width: 100%" >
    <el-table-column label="股票名称">
      <template #header>
          <span>股票名称 <el-icon @click="toggleHide" size="16">
            <component :is="isHidden ? 'Hide' : 'View'" />
          </el-icon></span>
      </template>
      <template #default="{ row }">
        <span>{{ isHidden ? '******' : row.name }}</span>
      </template>
    </el-table-column>
    <el-table-column  label="股票代码" >
      <template #default="scope">
        <el-text v-if="isHidden">
          ******
        </el-text>
        <el-text v-else>
          {{ scope.row.code }}
        </el-text>
      </template>
    </el-table-column>
    <el-table-column   sortable="custom" label="多/空">
      <template #default="scope">
        <el-text>
          {{directionChoices[scope.row.direction].label}}
        </el-text>
      </template>
    </el-table-column>
    <el-table-column prop="init_price" label="初始价格" />
    <el-table-column prop="current_price" label="当前价格" />
    <el-table-column  sortable="custom" label="涨跌幅度" width="120">
      <template #default="scope">
        <div v-if="scope.row.current_price">
          <el-text :type="scope.row.price_limit > 0 ? 'danger' : 'success'">
            {{ scope.row.price_limit !== null ? `${scope.row.price_limit}%` : '' }}
          </el-text>
        </div>
      </template>

    </el-table-column>

    <el-table-column prop="reason" label="备注"    width="300" />

    <el-table-column prop="created_at" sortable="custom" label="选股时间" />
    <el-table-column prop="updated_at" sortable="custom" label="结算时间"/>
    <el-table-column prop="holding_days"  label="持股天数"  :formatter="(row) => `${row.holding_days} 天`"/>
      <el-table-column label="开仓走势图" width="150" >
          <template #default="scope">
            <el-text v-if="isHidden">
              ******
            </el-text>
              <el-image
                  v-if="scope.row.opening_pic_url && !isHidden"
                      style="width: 150px; height: 150px;"
                      :src="scope.row.opening_pic_url"
                      fit="cover"
                      z-index="1111"
                      :hide-on-click-modal="true"
                      :preview-teleported="true"
                      :preview-src-list="[scope.row.opening_pic_url]"
                ></el-image>
          </template>
      </el-table-column>
    <el-table-column label="平仓走势图" width="150" >
      <template #default="scope">
        <el-text v-if="isHidden">
          ******
        </el-text>
        <el-image
            v-if="scope.row.closing_pic_url && !isHidden"
            style="width: 150px; height: 150px;"
            :src="scope.row.closing_pic_url"
            fit="cover"
            z-index="1111"
            :preview-teleported="true"
            :hide-on-click-modal="true"
            :preview-src-list="[scope.row.closing_pic_url]"
        ></el-image>
      </template>
    </el-table-column>
    <el-table-column  sortable="custom" label="是否成功">
      <template #default="scope">
        <el-text>
          {{statusChoices[scope.row.status].label}}
        </el-text>
      </template>

    </el-table-column>
  </el-table>
<el-button  style="width: 100%" @click="dialogVisible = true;
  // 重新初始化data
    data.name=''
    data.code=''
    data.reason=''
    data.init_price=1
    data.current_price=''
    data.status=2
    data.direction=1
    data.opening_pic_url=''
    data.closing_pic_url=''">
  <el-icon>
    <Plus />
  </el-icon>
新增股票
</el-button>
  <el-dialog
      v-model="dialogVisible"
      width="35%"
      @close="isUpdate= false"
  >
    <template #title>
      新增股票<el-text type="danger" size="large">
      (修改信息将把待定状态改为失败/成功,且不可恢复)
    </el-text>
    </template>
    <el-form label-position="right" :inline="true" >
      <el-row>
        <el-col :span="12">
          <el-form-item label="股票名称" label-position="right">
            <el-input v-model="data.name" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
        <el-form-item label="股票代码" label-position="">
          <el-input v-model="data.code" />
        </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="初始价格" label-position="">
            <el-input-number v-model="data.init_price" :precision="2" :step="0.1" :max="100000" />
          </el-form-item >
        </el-col>
        <el-col :span="12">
        <el-form-item  label="当前价格" label-position="">
          <el-input-number v-model="data.current_price" :precision="2" :step="0.1" :max="100000"  :disabled="!isUpdate"  />
        </el-form-item >
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
      <el-form-item  label="多/空" label-position="right">
        <el-switch
            v-model="data.direction"
            style="--el-switch-on-color: #ff4949; --el-switch-off-color: #13ce66"
            active-text="多"
            inactive-text="空"
            :active-value="1"
            :inactive-value="0"
        ></el-switch>
      </el-form-item>
        </el-col>
        <el-col :span="12">
      <el-form-item label="是否成功">
        <el-switch
            v-model="data.status"
            style="--el-switch-on-color: #ff4949; --el-switch-off-color: #13ce66"
            :active-icon="Check"
            :inactive-icon="Close"
            :active-value="1"
            :disabled="!isUpdate"
            :inactive-value="0"
        ></el-switch>
      </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
      <el-form-item label="开仓走势图" label-position="right">
        <el-upload
            action="https://vip.0vk.top/api/v1/article/write_page_pic_upload/"
            class="avatar-uploader"
            :headers=headers
            :show-file-list="false"
            name="chanlun"
            :on-success="(res, file) => handleUploadSuccess(res, file, 'opening')"
        >
          <img v-if="data.opening_pic_url" :src="data.opening_pic_url" class="avatar" />

          <el-icon v-else  class="avatar-uploader-icon"><Plus /></el-icon>
        </el-upload>

      </el-form-item>
        </el-col>
        <el-col :span="12">
      <el-form-item label-position="right" label="平仓走势图" >
        <el-upload
            :disabled="!isUpdate"
            action="https://vip.0vk.top/api/v1/article/write_page_pic_upload/"
            class="avatar-uploader"
            :headers=headers
            :show-file-list="false"
            name="chanlun"
            :on-success="(res, file) => handleUploadSuccess(res, file, 'closing')"
        >
          <img v-if="data.closing_pic_url" :src="data.closing_pic_url" class="avatar" />
          <el-icon v-else  class="avatar-uploader-icon"><Plus /></el-icon>
        </el-upload>

      </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label-position="right"  label="原因备注">
        <el-input
            style="width: 540px"
            :autosize="{ minRows: 4, maxRows: 6 }"
            type="textarea" v-model="data.reason" />
      </el-form-item>

      <h1><el-text type="danger">
        <el-icon size="22"><WarnTriangleFilled /></el-icon>
        一定要自己验证笔和中枢是否正确，不能过分相信软件</el-text> </h1>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false;">取消</el-button>
        <el-button type="primary" @click="submit">
          提交
        </el-button>
      </span>
    </template>
  </el-dialog>
    <p style="text-align: center;width: 100%">
        <el-pagination
                :hide-on-single-page="true"
                layout="total, sizes, prev, pager, next, jumper"
                :total="count"
                background
                style="margin: 0 auto"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :page-sizes="[10, 20, 50, 100]"
        />
    </p>
<!--排名模态框-->
  <el-dialog v-model="dialogTableVisible" title="股票排名" width="800" center>
    <el-table :data="stocksRank"
              :row-class-name="tableRowClassName" @sort-change="handleRankSortChange"
    >
      <el-table-column label="股票名称">
      <template #default="scope">
          <el-text v-if="isHidden">
            ******
          </el-text>
          <el-text v-else>
            {{ scope.row.name }}
          </el-text>
        </template>
      </el-table-column>
      <el-table-column label="股票代码">
        <template #default="scope">
          <el-text v-if="isHidden">
            ******
          </el-text>
          <el-text v-else>
            {{ scope.row.code }}
          </el-text>
        </template>
      </el-table-column>
      <el-table-column
          prop="count"
          label="出现次数"
          sortable
      />
      <el-table-column
          prop="price_limit_sum"
          label="波动幅度"
          sortable
          :formatter="(row) => `${row.price_limit_sum} %`"
      />
      <el-table-column
          prop="win_rate"
          label="胜率"
          sortable
          :formatter="(row) => `${row.win_rate} %`"
      />
      <el-table-column
          prop="holding_days"
          label="平均持仓"
          :formatter="(row) => `${row.holding_days} 天`"
      />
    </el-table>

  </el-dialog>
</template>

<script setup>
import {reactive,ref,onMounted} from "vue";
import { Check, Close,Plus } from '@element-plus/icons-vue'
import {
  AddChanlunStocks,
  getChanlunBaseinfo,
  getChanlunExport,
  getChanlunStocks,
  UpdateChanlunStocks
} from "@/network/main";
import { ElNotification  } from 'element-plus'
import {page_info} from "@/composables/pagination";
const results=ref([])
const data=reactive({
  name:'',
  code:'',
  reason:'',
  init_price:1,
  current_price:'',
  status:2,
  opening_pic_url:'',
  closing_pic_url:'',
  direction:1,
})
const showValue=ref(false)
const showValue1=ref(false)
const count=ref(0)
const isHidden=ref(false)
const dialogTableVisible = ref(false)
// 去敏
const toggleHide = () => {
  isHidden.value = !isHidden.value;
};
const tableRowClassName = ({row}) => {
  if (row.status === 1) {
    return 'error-row';
  } if(row.status === 0) {
    return 'success-row';
  }
  if(row.win_rate>=50){
    return 'error-row';
  }
  if(row.win_rate<=50){
    return 'success-row';
  }
};

const colors   = ref([
  { color: '#06d518', percentage: 0 },
  { color: '#47bc51', percentage: 20 },
  { color: '#327c4c', percentage: 40 },
  { color: '#7a3333', percentage: 60 },
  { color: '#b12929', percentage: 80 },
  { color: '#e80a0a', percentage: 100 },
])
const dialogVisible = ref(false)
const headers=ref({'Authorization': 'Bearer ' + localStorage.getItem('token')})
const handleUploadSuccess = (res, file, type) => {
  if (type === 'opening') {
    data.opening_pic_url = res.url;
  } else if (type === 'closing') {
    data.closing_pic_url = res.url;
  }
};

const isUpdate=ref(false)
const baseinfo=ref({})
const stocksRank=ref([])
const getStocks=()=>{
  getChanlunStocks(page_info).then(res=>{
    results.value=res.data.results
    count.value=res.data.count
  })
  getChanlunBaseinfo(page_info).then(res=>{
    baseinfo.value=res.data
    stocksRank.value=res.data.stocks_rank
  })
}
const id= ref('')
const statusChoices=ref([
  {value: 0, label: '失败'},
  {value: 1, label: '成功'},
  {value: 2, label: '待定'}
])
const directionChoices=ref([
  {value: '0', label: '空'},
  {value: '1', label: '多'},
])
const handleCurrentChange = (page)=> {
    page_info.page=page
    getStocks()
}
const handleSizeChange = (size)=> {
    page_info.size=size
    getStocks()
}
const handleExport=()=>{
  getChanlunExport()
}


const handleCellClick = (row,col) => {
    if(!col.label.includes('走势图')){
      isUpdate.value=true
      id.value=row.id
      data.name=row.name
      data.code=row.code
      data.direction=row.direction
      data.reason=row.reason
      data.init_price=row.init_price
      data.current_price=row.current_price
      data.opening_pic_url=row.opening_pic_url
      data.closing_pic_url=row.closing_pic_url
      data.status=row.status
      dialogVisible.value = true
    }

}
const submit=()=>{
  dialogVisible.value = false
  if(data.init_price<=0.1){
    ElNotification({
      title: '失败',
      message: '股票价格有误',
      type: 'error'
    });
    return
  }
  if(isUpdate.value){
    UpdateChanlunStocks(data,id.value).then(res=>{
      if(res){
        getStocks()
        ElNotification({
          title: '成功',
          message: '修改成功',
          type: 'success'
        });
      }
    })
  }
  else{
    data.status=2
    AddChanlunStocks(data).then(res=>{
      if(res){
        getStocks()
        ElNotification({
          title: '成功',
          message: '添加成功',
          type: 'success'
        });
      }

    })
  }

}
//排序
const handleSortChange = ({ column }) => {
  // 映射列标签到排序字段
  const sortFieldMap = {
    '涨跌幅度': column.order === 'descending' ? '-price_limit' : 'price_limit',
    '选股时间': column.order === 'descending' ? '-created_at' : 'created_at',
    '结算时间': column.order === 'descending' ? '-updated_at' : 'updated_at',
    '是否成功': column.order === 'descending' ? '-status' : 'status',
    '多/空': column.order === 'descending' ? '-direction' : 'direction',
  };
  page_info.ordering = sortFieldMap[column.label] || page_info.ordering;
  getStocks();
};
const handleRankSortChange = ({ column }) => {
  // 映射列标签到排序字段
  const sortFieldMap = {
    '出现次数': column.order === 'descending' ? '-count' : 'count',
    '波动幅度': column.order === 'descending' ? '-price_limit_sum' : 'price_limit_sum',
    '胜率': column.order === 'descending' ? '-win_rate' : 'win_rate',
    '平均持仓': column.order === 'descending' ? '-holding_days' : 'holding_days',
  };
  page_info.ordering = sortFieldMap[column.label] || page_info.ordering;
  getChanlunBaseinfo(page_info);
};

onMounted(()=>{
  getStocks()
})
</script>

<style scoped>

.el-statistic {
    --el-statistic-content-font-size: 28px;
}
.statistic-footer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  font-size: 13px;
  color: #92989d;
  margin-top: 16px;
}

.statistic-footer .footer-item {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.green {
  color: var(--el-color-success);
  margin-left: 10px;
}
.red {
  color: var(--el-color-error);
  margin-left: 10px;

}
</style>

<style>
.el-table .error-row {
  --el-table-tr-bg-color: var(--el-color-danger-light-9);
}
.el-table .success-row {
  --el-table-tr-bg-color: var(--el-color-success-light-9);
}
 .avatar-uploader .el-upload {
   border: 1px dashed var(--el-border-color);
   border-radius: 6px;
   cursor: pointer;
   position: relative;
   overflow: hidden;
   transition: var(--el-transition-duration-fast);
 }

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>