
import { ref} from "vue";
const CodeActive=ref(true)
const CodeMessage=ref("获取验证码")

const code=()=>{
    let timer=10
    CodeActive.value=false
    const timer1=setInterval(()=>{
        if (timer===0){
            clearInterval(timer1)
            CodeMessage.value=`获取验证码`
            CodeActive.value=true
        }
        else {
            timer--
            CodeMessage.value=`${timer}秒后重试`
        }
    },1000)
    console.log('发送验证码')

}

export function  SendCode() {
    return code()
}
export {CodeActive,CodeMessage}
