import { request } from './request'

export function getGoodsList(page_info,) {
    return request({
        url: "/goods/",
        params: {
            page: page_info.page,
            size: page_info.size,
            search: page_info.search,

        },
    })

}