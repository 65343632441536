<template>


  <el-descriptions
      size="32px"
      column=2
      border
      style="max-width: 70%;margin: 0 auto"
  >
  <template #extra>
    <el-button v-if="ChangeInfo" type="danger" @click="ChangeInfo=false">取消修改</el-button>
  </template>
    <el-descriptions-item>
      <template #label>
        <el-icon><PictureRounded /></el-icon>
        头像
      </template>
      <el-upload
          :before-upload="beforeAvatarUpload"

      >
        <el-avatar v-if="data.imageUrl" :size="80" :src="data.imageUrl" >
        </el-avatar>
        <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
      </el-upload>
    </el-descriptions-item>
    <el-descriptions-item  >
      <template #label>
        <el-icon ><User /></el-icon>
        <span> 用户名</span>
      </template>
      <el-input  v-model="data.username" @change="Submit_Info" v-if="ChangeInfo"></el-input>
      <span v-else  @click="HandelClick">{{ data.username }}</span>

    </el-descriptions-item>
    <el-descriptions-item >
      <template #label>
        <el-icon><Male /></el-icon>
        /
        <el-icon><Female /></el-icon>
          性别
      </template>
      <el-switch
          v-model="data.sex"
          inline-prompt
          @change="Submit_Info" v-if="ChangeInfo"
          style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
          active-text="男"
          inactive-text="女"
          active-value="男"
          inactive-value="女"
      />
      <el-tag v-else @click="HandelClick" size="large">
        {{ data.sex }}</el-tag>
    </el-descriptions-item>
    <el-descriptions-item >
      <template #label>
        年龄
      </template>
    <el-input-number v-model="data.age" :min="1" :max="120"
                     @blur="Submit_Info" v-if="ChangeInfo"

    />
      <span v-else @click="HandelClick">
        {{ data.age }}</span>
    </el-descriptions-item>
    <el-descriptions-item  >
      <template #label>
        <el-icon><Iphone /></el-icon>
     电话
      </template>
      <el-input  v-model="data.phone" @change="Submit_Info" v-if="ChangeInfo"></el-input>
      <span v-else @click="HandelClick">{{ data.phone }}</span>

    </el-descriptions-item>

    <el-descriptions-item  label="介绍自己">太强无法介绍！！！！！！</el-descriptions-item>

  </el-descriptions>

</template>

<script setup>
import {ref,reactive} from "vue";
import {ElNotification,ElMessage} from "element-plus";
const ChangeInfo = ref(false)
const data=reactive({
  username:'111',
  sex:'男',
  age:18,
  phone:'11111111111',
  imageUrl :require('@/assets/404.gif'),

})
//头像上传
const beforeAvatarUpload=(rawFile)=> {
  if (rawFile.type !== 'image/jpeg' && rawFile.type !== 'image/png' && rawFile.type !== 'image/gif') {
    ElMessage.error('不支持的图片格式!')
    return false
  } else if (rawFile.size / 1024 / 1024 > 2) {
    ElMessage.error('图片大小超过 2MB!')
    return false
  }
  return true
}
const HandelClick=()=>{
  ChangeInfo.value=true
  ElNotification({
    title: '正在修改信息',
    message: '修改用户信息',
    type: 'warning',
  })
}
const Submit_Info=()=>{
  ChangeInfo.value=false
  ElMessage.success('信息修改成功')
}
</script>

<style scoped>

</style>