<template>
  <el-row justify="center">
    <Base_Fliter @item_class="item_class"/>
  </el-row>
  <el-divider>
    {{ Category }}
  </el-divider>
  <el-row   class="row-bg" :gutter="30"
  >
    <GoodsItem/>
  </el-row>
</template>

<script setup>
import {  ref  } from 'vue'
import Base_Fliter from "@/components/Base_Fliter";
import GoodsItem from "@/components/goods/goodslist/GoodsItem";

//分割横线分类名称
const Category=ref('全部')

const item_class=(val)=>{
  Category.value=val
}
</script>

<style scoped>

</style>