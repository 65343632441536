<template>
  <div style="margin-top: 20px">
    <el-tooltip
        effect="dark"
        :content="prev_one.title"
        placement="top"
        v-if="prev_one"
    >
      <el-link :underline="false" style="float: left;" type="info" :href="'/article/'+prev_one.id">
        <el-icon :size="22"><DArrowLeft /></el-icon>上一篇
      </el-link>
    </el-tooltip>

    <el-button v-else style="float: left;" type="info" link disabled>
      暂无
    </el-button>

    <el-tooltip
        effect="dark"
        :content="next_one.title"
        placement="top"
        v-if="next_one"
    >
      <el-link :underline="false" style="float: right;" type="info" :href="'/article/'+next_one.id">

        下一篇
        <el-icon :size="22"><DArrowRight /></el-icon>

      </el-link>
    </el-tooltip>

    <el-button v-else style="float: right;" type="info" link disabled>
      到底啦！
    </el-button>

  </div>
</template>

<script setup>
import {toRefs,defineProps} from "vue";
const props = defineProps({
  prev_one: {
    type: Object,
    default: null
  },
  next_one: {
    type: Object,
    default: null
  }
})
const {prev_one,next_one} = toRefs(props)
</script>

<style scoped>

</style>