<template>

  <div class="footer">
    <p> Copyright ©2022 VK All Rights Reserved</p>
    <p>陕ICP备 :
      <el-link href="http://beian.miit.gov.cn/"
      >2022001273号
        <el-icon><StarFilled /></el-icon></el-link>

    </p>
    <span style="margin-bottom: 10px">
    <a href="https://www.upyun.com/?utm_source=lianmeng&utm_medium=referral">
      <img :src="require('@/assets/ypaiyun.png')" width="35" height="35"/>
    </a>
    提供CND加速/云存储服务
</span>

  </div>
  <div style="height: 50px;">

  </div>
</template>

<script setup>

</script>

<style scoped>
.footer{
  font-size:var(--el-font-size-small);
  text-align: center;
}
</style>