<template>
  <dv-charts :option="config1" />
  111
</template>

<script setup>
import {ref} from "vue";
import {GetSiteInfoData} from "@/network/main";
const data = ref()

 GetSiteInfoData('category').then(res=>{
  data.value=res.data
})

const config1 = ref({
  title: {
    text: '文章分类占比统计'
  },
  series: [
    {
      type: 'pie',
      data: data,
      insideLabel: {
        show: true
      }
    }
  ],
  roseType: true

})

</script>

<style scoped>

</style>