import {createWebHistory, createRouter} from "vue-router";
import IndexPage from "@/views/IndexPage.vue";
import ArticleList from "@/views/article/ArticleList";
import ArticleDetail from "@/views/article/ArticleDetail";
import GoodsList from "@/views/goods/GoodsList";
import NotFoundPage from "@/views/NotFoundPage";
import SiteInfo from "@/views/SiteInfo";
import NProgress from "nprogress";
import TimeLogPage from "@/views/main/TimeLogPage";
import WritePage from "@/views/article/WritePage";
import UserCenter from "@/views/user/UserCenter";
import UserInfo from  "@/views/user/usercenter/UserInfo";
import UsercenterArticles from  "@/views/user/usercenter/UsercenterArticles";
import SchedulePage from  "@/views/user/usercenter/SchedulePage";
import TodoList from "@/views/user/usercenter/TodoList.vue";
import 'nprogress/nprogress.css'
import HomePageCarousel from "@/views/user/usercenter/HomePageCarousel.vue";
import BaseSetting from "@/views/user/usercenter/BaseSetting.vue";
import {GetSiteSettings} from "@/network/main";
import ChanLun from "@/views/user/usercenter/ChanLun.vue";
import BiShe from "@/views/BiShe.vue";

const routes = [
    {
        path: "/",
        name: "IndexPage",
        component: IndexPage,
        meta: {
            title: "首页"
        }
    },
    {
        path:"/bishe",
        name:"Bishe",
        component: BiShe,
        meta: {
            NoNav: true,
            title: "毕设"
        }

    },
    {
        path: "/article",

        children: [
            {
                path:'',
                name:"ArticleList",
                component: ArticleList,
                meta: {
                    title: "文章列表"
                }
            },
            {
                path: "write",
                component:WritePage,
                meta: {
                    NoNav: true,
                    title: "写文章"
                }

            },
            {
                path: "update/:id(\\d+)",
                component:WritePage,
                meta: {
                    NoNav: true,
                }
            },
            {
                path: ":id(\\d+)",
                component: ArticleDetail,
                meta: {
                    setScroll:true,
                    bg: "WhiteColor",
                    title: '文章详情',
                }
            },

        ]

    },
    {
      path: "/user",
      children: [{
          path:'center',
          name:'UserCenter',
          component:UserCenter,
          meta: {
              title: "个人中心"
          },
          children: [
                {
                    path: "",
                    name: "UserInfo",
                    component:UserInfo ,
                    meta: {
                        title: "个人简介"
                    }
                },
              {
                  path: "articlelist",
                  name:"UsercenterArticleList",
                  component: UsercenterArticles,
                  meta: {
                      title: "我的文章"
                  }
              },

              {
                  path: "schedule",
                  name:"Schedule",
                  component: SchedulePage,
                  meta: {
                      title: "日程计划"
                  }
              },
              {
                  path: "todolist",
                  name:"TodoList",
                  component: TodoList,
              },
              {
                  path:'chanlun',
                  name: "Chanlun",
                  component:ChanLun,
                    meta: {
                        title: "缠论"
                    }

              },
              {
                  path: "carousel",
                  name: "CarouselPage",
                  component: HomePageCarousel,
                  meta: {
                      title: "首页轮播图"
                  }
              },
              {
                    path: "basesetting",
                    name: "BaseSetting",
                    component: BaseSetting,
                    meta: {
                        title: "网站基本设置"
                    }

              },

            ]

      },
  ]

    },
    {
        path: "/timeline/",
        name: "TimeLineLog",
        component: TimeLogPage,
        meta: {
            title: "建站历程"
        }
    },
    {
        path: "/SiteInfo/",
        name: "SiteInfo",
        component:SiteInfo,
        meta: {
            NoNav: true,
            SiteInfo:true,
            title:"大屏数据展示"

        }
    },

    {
        path:'/goods/',
        name:"goodslist",
        component: GoodsList,
    },
    {
        path: "/404/",
        name: "NotFoundPage",
        component: NotFoundPage,
        meta: {
            title: "404"
        }
    },
    {
    path: '/:pathMatch(.*)',
    redirect: '/404/',
    hidden: true
}

];

const router = createRouter({
    history: createWebHistory(),
    routes,
});
router.beforeEach(() => {
    NProgress.start();
    // 动态设置title
    GetSiteSettings().then(res=>{
        document.title = res.data[0]['site_name']+'——'+router.currentRoute.value.meta.title;
    })
});

router.afterEach(() => {
    NProgress.done();
});
export default router;