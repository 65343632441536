import { request } from './request'
import QueryString from "qs";
export function getArticleList(page_info,categorylist='全部') {

    return request({
        url: "/article/",
        params: {
            page: page_info.page,
            size: page_info.size,
            ordering: page_info.ordering,
            search: page_info.search,
            category: categorylist
        },
        paramsSerializer: function(params) {
            return QueryString.stringify(params, {arrayFormat: 'repeat'})
        }
    })
}

export function getCategoryList() {
    return request({
        url: "/article/categorylist",
    })
}
//文章增删改查
export function SubmitArticle(data) {
    return request({
        url: "/article/",
        method:"post",
        data: data
    })

}
export function UpdateArticle(data,id) {
    return request({
        url: "/article/"+id+"/",
        method:"patch",
        data: data
    })
}
export function DeleteArticle(id,ids=[]) {
    return request({
        url: "/article/"+id+"/",
        method:"delete",
        data:{'ids':ids},
    })
}
export function getArticleDetail(id=1) {
    return request({
        url: "/article/"+id,
    })
}

//日程计划增删改查
export function AddSchedule(data) {
    data.article_category=4         //日程计划的分类id
    return request({
        url: "/article/schedule/",
        method:"post",
        data: data
    })
}
export function UpdateSchedule(data,id) {
    data.article_category=4         //日程计划的分类id
    return request({
        url: "/article/schedule/"+id+'/',
        method:"patch",
        data: data
    })
}
export function DeletePlan(id) {
    return request({
        url: "/article/schedule/"+id+"/",
        method:"delete",
    })
}
export function GetSchedule(month,year) {
    return request({
        url: "/article/schedule/",
        params: {
            month: month,
            year: year,
        },
        method:"get",
    })

}
export function GetFileLibrary() {
    return request({
        url: "/article/filelibrary/",
        method:"get",
    })

}