<template>
  <el-backtop style="background-color: #282c34" :right="100" :bottom="100" />

  <el-row :gutter="10" >

  <el-col :span="4"   class="hidden-sm-and-down" style="position: relative" >
    <div  id="fixed" style="text-align: center;width: 220px;overflow-y: auto">
      <div style="font-size: x-large;font-weight: bold;margin-top: 10px" class="toc-title">目录</div>
      <div class="toc" id="toc" style="background-color: #f8f9fa!important;font-size: large;margin-top: 10px;" ></div>
    </div>

  </el-col>
  <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16" >

    <h1>{{data.title}}</h1>
    <div style="display: flex">
      <div style="justify-content: left;flex: 1">

      <el-space  wrap>
        <el-tag style="margin-left: 10px" type="info" v-if="data.article_category" effect="dark">
        {{category}}
      </el-tag>
        <small style="  color: #adb5bd !important">发布于:{{data.created_at}}</small>
        <span>
        <small>
          <el-icon :size="22" color="#409EFC"><View  /></el-icon>
          <el-text type="info">{{read_count}}</el-text>
                  </small>

      </span>
        <span>
        <small >
          <el-icon color="orange" :size="22"><Timer /></el-icon>
         <span style="  color: #adb5bd !important">
           预计阅读时长:{{data.read_time}} min
         </span>
        </small>
</span>
        <small >
          <el-icon :size="22" class="is-loading"><Orange /></el-icon>
          <span style="  color: #adb5bd !important">
           {{data.words}} 字
         </span>
        </small>
        <small >
          <el-icon  :size="22"><Position /></el-icon>
          <span >
           最近编辑于:<TimeAgo :date="data.updated_at">

          </TimeAgo>
         </span>
        </small>
      </el-space>
      </div>
      <div style="justify-content: right">
        <el-space wrap>
        <el-link :underline="false" @click="$router.push('/article/update/'+$route.params.id)">
        <el-button>
          <el-icon :size="22" >
            <Edit />
          </el-icon>
          编辑文章</el-button>
      </el-link>
      <el-button @click="deleteArticle"  type="danger" bg text>
        <el-icon :size="22" >
          <Delete />
        </el-icon>
      </el-button>
        </el-space>

      </div>
    </div>

    <el-skeleton  :rows="5" animated v-if="loading" />
    <div v-if="ShowContent" class="ck-content" v-html="data.content">
  </div>
  <div  v-else style="text-align: center;margin-top: 10px" >

    <div class="ck-content" v-html="data.short_content">
  </div>
    <el-skeleton :rows="5" animated />
    <el-icon :size="32" color="red"><Lock /></el-icon>
    <p>
      <el-button type="danger" @click="unlock" round>
        点击解锁文章
      </el-button>
    </p>
  </div>

    <div>
    <PayPage></PayPage>
    <ChangeArticle :prev_one="prev_one" :next_one="next_one" />
    <CommentPage :article_id="route.params.id"></CommentPage>
    </div>
  </el-col>
    <el-col v-if="picList[0]" :xs="24" :sm="24" :md="4" :lg="4" :xl="4" >
      <p>图片预览：</p>
      <el-image
          style="position: fixed"
          :src="picList[0]"
          :zoom-rate="1.2"
          :preview-src-list="picList"
          fit="cover"
      />
    </el-col>
  </el-row>
</template>

<script setup>
import '@/assets/article/css/highlight.css'
import hljs from '@/assets/article/js/highlight.js';
import {DeleteArticle, getArticleDetail, getCategoryList} from "@/network/article";
import {useRoute,useRouter} from "vue-router";
import "@/assets/article/css/ckeditor.css"
import ChangeArticle from "@/components/article/article_detail/ChangeArticle";
import {ElMessage,ElMessageBox} from "element-plus";
import {onMounted,onUpdated, markRaw, ref , reactive } from 'vue';
import {Lock,Delete} from '@element-plus/icons-vue'
import {GetSiteSettings} from "@/network/main";
import PayPage from "@/components/article/article_detail/PayPage.vue";
import CommentPage from "@/components/article/article_detail/CommentPage.vue";
import TocHelper from 'toc-helper'
import TimeAgo from "@/components/article/article_detail/TimeAgo.vue";

const route = useRoute ()
const router =useRouter()
const categorys=ref('')
const category=ref('')
const loading = ref(true)
const picList=ref([])
const getPicList=()=>{
  //提取富文本中图片链接
  let reg = /<img.*?(?:>|\/>)/gi;
  let arr = data.content.match(reg);
  //获取data-href属性符合eslint规则
  if (arr){
    for (let i = 0; i < arr.length; i++) {
      let src = arr[i].match(/\sdata-href=['"](.*?)['"]/);
      //获取图片地址
      if (src[1]) {
        picList.value.push(src[1]);
      }
    }
  }

}
const data=reactive({
  title:'',
  short_content:'',
  article_category:'',
  tags:[],
  content:'',
  words: 0,
  characters: 0,
  read_time:0,
  created_at:'',
  updated_at:'',
})
// 非数据库模型字段
const read_count=ref()

const ShowContent=ref(true)
const next_one=ref({})
const prev_one=ref({})

const getArticle=()=> {
  getArticleDetail(route.params.id).then((res) => {
    if (res.data.content == '请输入密码后查看') {
      unlock()
      data.short_content = res.data.short_content
      ShowContent.value = false
      loading.value = false

    } else {
      getCategoryList().then((res1) => {
        GetSiteSettings().then(res2 => {
          document.title = res.data.title + '——' + res2.data[0]['site_name']
        })
        read_count.value=res.data.read_count
        categorys.value = res1.data
        data.content = res.data.content
        getPicList()
        data.title = res.data.title
        data.created_at = res.data.created_at
        data.article_category = res.data.article_category
        data.read_time=res.data.read_time
        data.words=res.data.words
        data.updated_at=res.data.updated_at
        next_one.value = res.data.next_one
        prev_one.value = res.data.previous_one
        if (res.data.article_category) {
          category.value = categorys.value.filter(item => item.id == res.data.article_category)[0].articleCategoryName
        }
        ShowContent.value = true
        loading.value = false

      })
      loading.value = false

    }




  }).catch(error => {
    ElMessage.error(error.message)
    router.push('/404/')
  })
}
const unlock = () => {

  ElMessageBox.prompt('请输入密码', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    inputPattern: /\S/,
    inputErrorMessage: '密码不能为空'
  })
}

const deleteArticle= () => {
  ElMessageBox.confirm(
      '确定要删除此文章？',
      '确认删除',
      {
        type: 'error',
        icon: markRaw(Delete),
      }
  ).then(()=>{
    DeleteArticle(route.params.id).then((res) => {
    if (res.status == 204) {
      ElMessage.success('删除成功')
      router.go('-1')
    }

  })
  })

}
onMounted(() => {
  getArticle()
  setTimeout(() => {
    //判断id为toc的元素是否存在
      new TocHelper("#toc", {
            fixedSelector: "#fixed",
            contentSelector: ".ck-content",
            collapsedLevel: 2,
            scrollOffset: 84,

          },
      );


    hljs.highlightAll();

  }, 500)
})
onUpdated(() => {
  getArticle()

})
</script>


<style scoped>

</style>
<style>

h1{
  text-align: center;
  font-weight: 600;
  font-size: 1.5rem;
}
img{
  max-width: 100%;
  max-height: 100%;
}
div[data-w-e-type="todo"]>input{
  -webkit-appearance: none;
  cursor: pointer;
  display: inline-block;
  position: relative;
  color: greenyellow;
  width: 16px;
  height: 16px;
  left: -5px;
  vertical-align: middle;
  border: 0;
  margin-right: 5px;
  right: 0;
  margin-left: 0;
}
div[data-w-e-type="todo"]>input[type="checkbox"][checked]:before {
  background: #26ab33;
  border-color: #26ab33;

}
div[data-w-e-type="todo"]>input:before{
  display: block;
  position: absolute;
  box-sizing: border-box;
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid #333;
  border-radius: 2px;
  transition: box-shadow .25s ease-in-out,background .25s ease-in-out,border .25s ease-in-out;
}
div[data-w-e-type="todo"]>input[type="checkbox"][checked]:after {
  border-color: #fff;
}
div[data-w-e-type="todo"]>input[type="checkbox"]:after {
  display: block;
  position: absolute;
  box-sizing: content-box;
  pointer-events: none;
  content: "";
  left: calc(16px/3);
  top: calc(16px/5.3);
  width: calc(16px/5.3);
  height:calc(16px/2.6);
  border-left: 0 solid transparent;
  border-bottom: calc(16px/8) solid transparent;
  border-right: calc(16px/8) solid transparent;
  border-top: 0 solid transparent;
  transform: rotate(45deg);
}
span>input[type="checkbox"]{

  color: greenyellow;
  width: 16px;
  height: 16px;
  left: -5px;
  vertical-align: middle;
  border: 0;
  margin-right: 5px;
  right: 0;
  margin-left: 0;
}
.bitoc-nav a:before{
  height: 0!important;
}
.bitoc-nav a:hover, .bitoc-nav a.active{
  color:#FC9D1D!important;
  background-color: #e3f2fd!important;
  background: 0 0;
  border: 0;
  border-radius: .25rem;
}
.bitoc-ml-1{
  margin-top: 10px;
  height: 40px;
}
.bitoc-ml-2{
  margin-top: 10px;
  height: 40px;

}
.bitoc-ml-3{
  margin-top: 10px;
  height: 40px;
}
.bitoc-ml-4{
  margin-top: 10px;
  height: 40px;
}
.bitoc-ml-5{
  margin-top: 10px;
  height: 40px;
}
.scroll{
  overflow:scroll;
  width:500px;
  height:200px;
  margin-top:20px;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.bitoc {
  max-height: 40.5rem;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color:#DCDCDC;
}
</style>