<template>

  <el-tabs tab-position="right" >
    <el-tab-pane v-for="year in results" :label="Object.keys(year)[0] " :key="Object.keys(year)[0]">
      <el-collapse class="card" v-for="(result,months) in year" :key="months" v-model="now_month" >
        <el-collapse-item  v-for="(data,month) in result" :key="month"
                           :name="month.toString()">
          <template #title>
           <el-tag       effect="light"
                         type="danger"
           >{{month+'月'}}</el-tag>
          </template>

          <el-timeline>
        <el-timeline-item v-for="i in data"
                          :key="i.id"
                          :timestamp="new(Date)(i.created_at).toLocaleString()"
                          placement="top"
                          :type="level[i.important_level]"
                          center
                          :hollow="i.important_level==1"
        >
          <el-card class="card"  shadow="hover" @click="$router.push('/article/'+i.id)">
            <h4>{{i.title}}

            </h4>
            <p v-html="i.content"></p>

            <small style="color: #767676">最近更新于:{{format_time(i.updated_at)}} </small>


          </el-card>

        </el-timeline-item>
      </el-timeline>
        </el-collapse-item>
      </el-collapse>
    </el-tab-pane>
  </el-tabs>

</template>


<script setup>
import {getTimeLogList} from "@/network/main";
import {ref} from "vue";
const results=ref('')
const level=ref({
  1:'primary',
  2:'success',
  3:'warning',
  4:'danger'
})
const now_month=ref(results.value[0])

getTimeLogList().then((res) => {
  results.value=res.data
  now_month.value=Object.keys(Object.values(res.data[0])[0])[0]

})
const format_time=(time)=>{
  return new(Date)(time).toLocaleString()
}
</script>

<style scoped>

</style>