<template>
  <el-affix >
  <el-menu
      mode="horizontal"
      :router="true"
      active-text-color="#1bc5bd"
      text-color="#1bc5bd"
  >

    <el-menu-item  index="/">
    <el-icon><HomeFilled /></el-icon>首页

    </el-menu-item>
    <el-menu-item id="SiteInfo" index="/SiteInfo">
    数据统计

    </el-menu-item>
    <NavSearch/>
    <div class="flex-grow">
    <el-menu-item v-if="!token" index="">
      <BaseUserInfo :token="token"/>
    </el-menu-item>
      <el-menu-item v-else  index="/user/center">
        个人中心
      </el-menu-item>
    <el-menu-item  index="/timeline">
      <el-icon><WindPower /></el-icon>

      建站历程

    </el-menu-item>

    <el-menu-item index="" :class="theme?'is_dark':''"  @click="toggledark()">
      <el-icon v-if="theme" style="height: 56px"><Sunny/> </el-icon>
      <el-icon v-else style="height: 56px"><Moon/> </el-icon>
    </el-menu-item>

    <el-switch
          style="margin-top: 15px"
          v-model="language"
          active-text="EN"
          inactive-text="ZH"
          @change="SwitchLanguage"
      />
    </div>

  </el-menu>
  </el-affix>


</template>


<script setup >
import {ref,defineProps} from "vue";
import {toggleDark} from "@/composables/dark";
import BaseUserInfo from "@/components/BaseUserInfo";
import NavSearch from "@/components/main/index/NavSearch";
const theme=ref(false)
const toggledark = ()=> {
  theme.value = !theme.value
  return toggleDark()
}
const props=defineProps({
  SwitchLanguage:Function,
})
const SwitchLanguage=ref(props.SwitchLanguage)
const language=ref(false)
const token=localStorage.getItem('token')

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.flex-grow {
  display: flex;
  position: absolute;
  right: 20px;
}
#SiteInfo{
  color: darksalmon;
}

</style>
