<template>
  <span style="font-size: 13px">{{ content }}</span>

</template>

<script setup>
import {ref, defineProps, toRefs, onUpdated} from "vue";
const content =ref('')
const props=defineProps({
  date: {
    type: String,
    default: new Date().toString()
  },
  time: {
    type: Number,
    default: 0,
  }
})
const {date,time}=toRefs(props)
const diffTime = () =>
{
      if(!date&&!time) {

        return '刚刚'
        }

  var minute = 1000 * 60;
      var hour = minute * 60;
      var day = hour * 24;
      var month = day * 30;

      var now = new Date().getTime();
      var dateTimeStamp = new Date(date.value).getTime()
      var diffValue = now - dateTimeStamp;

      var monthC =diffValue/month;
      var weekC =diffValue/(7*day);
      var dayC =diffValue/day;
      var hourC =diffValue/hour;
      var minC =diffValue/minute;
  if (parseInt(monthC) >1) {
    content.value = date
  }else {
    if (parseInt(monthC) == 1) {
      content.value = parseInt(monthC) + '个月前'
    } else if (parseInt(weekC) >= 1) {
      content.value = parseInt(dayC) + '周前'
    } else if (parseInt(dayC) > 1) {
      content.value = parseInt(dayC) + '天前'
    } else if (parseInt(dayC) === 1) {
      content.value = '昨天'
    } else if (parseInt(hourC) >= 1) {
      content.value = parseInt(hourC) + "小时前";
    } else if (parseInt(minC) >= 1) {
      content.value = parseInt(minC) + "分钟前";
    } else {
      content.value = "刚刚";
    }
  }

}
onUpdated(() => {
  diffTime()
})
</script>

<style>

</style>

