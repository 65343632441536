<template>
  <div v-loading="loading">

  <el-tabs  v-if="FileList" :tab-position="position">

    <el-tab-pane v-for ="(v,k) in FileList" :key="k"  :label="k" @click="lazy=''">

      <el-row style="height: 600px;overflow-y: auto;overflow-x: hidden">

        <el-col
            v-for="i in v"
            :key="i.name"
            :xs="10" :sm="6" :md="4" :lg="4" :xl="4"
            :offset="2"
        >
          <el-image
                          style="width: 120px; height: 120px"
                          @dblclick="SelectPic"
                          :src="BasePath+k+'/'+i.name+UrlType"  />
        </el-col>
      </el-row>
    </el-tab-pane>
  </el-tabs>
    <el-alert v-else type="error" title="文件库为空">

    </el-alert>
  </div>
</template>

<script setup>
import {GetFileLibrary} from "@/network/article";
import {ref,defineExpose,defineProps} from "vue";
import {ElMessage} from "element-plus";
const loading=ref(true)
const FileList=ref({})
const BasePath=ref('')
const UrlType=ref('')
const props=defineProps({
  set_picurl: {
    type: Function,
  }
})
const lazy=ref('lazy')
const position=ref('right')
const showWarning=ref(true)
const GetFileList=()=> {
  if(window.innerWidth<768){
    position.value='top'
  }
  GetFileLibrary().then(res => {
    if(res.status===200){
      FileList.value = res.data['files']
      BasePath.value = res.data['base_path'] //'/0游客/'
      UrlType.value = res.data['url_type']   //云处图片
      loading.value = false
      // for (let i in FileList.value) {
      //   for (let j in FileList.value[i]) {
      //     srcList.value.push(url + BasePath.value + i + '/' + FileList.value[i][j].name + UrlType.value)
      //   }
      // } //图片url列表
    }
    else{
      loading.value = false
      FileList.value={}

    }
  })
}
const SelectPic=(e)=>{
  const target= e.currentTarget
  props.set_picurl(target.outerHTML)
}
if(showWarning.value){
  ElMessage({
    message: '双击图片即可插入',
    type: 'warning',
    showClose: true,
    onClose:()=>{
      showWarning.value=false
    }
  })
}

GetFileList()

defineExpose({GetFileList})

</script>

<style scoped>

</style>