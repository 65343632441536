<template>
  <div style="margin-top: 200px;margin-bottom: 50px;text-align: center">
    <el-popover
        placement="top"
        trigger="hover"
        popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 20px;"
        :width="300"
    >
      <template #reference>

      <el-button  type="danger" size="large" round>
    赞赏
  </el-button>
      </template>
      <template #default>
      <el-image  :src="require('@/assets/article/image/free.jpg')"  />

      </template>
    </el-popover>
  </div>
</template>

<script>
export default {
  name: "PayPage"
}
</script>

<style scoped>

</style>