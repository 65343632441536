<template>
  <el-form :model="data"
           ref="ruleFormRef"
           :rules="rules">
    <el-menu
        mode="horizontal"
        text-color="#1bc5bd"
        default-active="222"
        style="width: 100%;"
    >
      <el-col style="margin-left: 8px" :xs="3" :sm="2" :md="2" :lg="2" :xl="2">
      <el-link type="info" :underline="false" @click="$router.back(-1)">
        <el-icon :size="22"><Back /></el-icon>
        返回</el-link>

      </el-col>

      <el-col  :xs="6" :sm="10" :md="6" :lg="6" :xl="6" >
      <el-input
              v-model="data.short_content"
              maxlength="25"
              placeholder="默认截取文章前25个字"
              show-word-limit
              type="text"
          />
      </el-col>
      <el-col                  v-if="!ShowimportLevel"
                               :xs="5" :sm="4" :md="3" :lg="3" :xl="3" :offset="1">

      <el-select v-model="categorys.value"
                 @change="SelectTimeline"
                 clearable placeholder='请选择分类'>
            <el-option
                v-for="item in categorys"
                :key="item.label"
                :label="item.articleCategoryName"
                :value="item.id"
            />
          </el-select>
      </el-col>
      <el-col v-if="ShowimportLevel"
              :xs="5" :sm="4" :md="3" :lg="3" :xl="3" :offset="1">
      <el-form-item  prop="important_level">
        <el-select v-model="data.important_level"  placeholder="请选择重要程度"
                   clearable
                   @clear="ShowimportLevel=false"
                   size="large">
          <el-option label="通知" value="1"></el-option>
          <el-option label="成功" value="2"></el-option>
          <el-option label="警告" value="3"></el-option>
          <el-option label="紧急" value="4"></el-option>

        </el-select>
      </el-form-item>
      </el-col>
        <el-col :xs="5" :sm="4" :md="3" :lg="3" :xl="3" :offset="1">
          <el-select
              v-model="tag"
              multiple
              filterable
              allow-create
              default-first-option
              :reserve-keyword="false"
              placeholder="选择或新建标签"
          >
          </el-select>
        </el-col>
      <el-col  :xs="5" :sm="4" :md="2" :lg="2" :xl="2" :offset="1">

      <el-switch
            v-model="isSecret"
            style="margin-left: 24px;--el-switch-on-color: #ff4949; --el-switch-off-color: #13ce66"
            :active-icon="Lock"
            size="large"
            @change="setPwd"
        />
      </el-col>

      <el-col  :span="2">

      <el-tooltip content="图片库" placement="bottom" effect="dark" >
          <el-button type="warning" @click="RefreshPicLib"  text bg>
            <el-icon :size="20"><Picture/></el-icon>
          </el-button>
          </el-tooltip>
          <el-drawer
              v-model="drawer"
              title="个人媒体资源库(双击图片自动插入)"
              :size="size"
              :direction="position"
          >
            <FileLibreary :set_picurl="SetPicurl" ref="RefreshPic"></FileLibreary>
          </el-drawer>

      </el-col>


        <el-button  type="primary" @click="submit(ruleFormRef,'存入草稿')" plain round>存入草稿</el-button>
        <el-button   type="danger" @click="submit(ruleFormRef)"  round>{{sub_text}}</el-button>
    </el-menu>
<!--    时间线日志-->

<!--    富文本内容-->
    <el-form-item  prop="content" >
  <WangEditor ref="EditorInsert"  v-model:content="data.content" v-model:read_time="data.read_time" v-model:words="data.words"></WangEditor>
  </el-form-item>
  <div  class="ck ck-word-count" style="display: flex;">
    <el-space wrap>
      <el-icon :size="22" color="orange" class="is-loading" style="margin-left: 30px"><Orange /></el-icon>
    <div class="ck-word-count__words">单词数: {{data.words }}</div>
    <span>预计阅读时间 <el-icon :size="22"><Timer /></el-icon>{{data.read_time}} min</span>
    </el-space>
  </div>
  </el-form>
</template>
//富文本编辑器
<script setup>
import { reactive,ref } from 'vue';
import {getCategoryList, SubmitArticle, getArticleDetail, UpdateArticle} from "@/network/article";
import {ElMessage,ElMessageBox,FormInstance} from 'element-plus'
import {convert} from 'html-to-text'
import {useRouter,useRoute} from "vue-router";
import {Lock} from '@element-plus/icons-vue';
import WangEditor from "@/components/article/write/WangEditor.vue";
import FileLibreary from "@/components/article/write/FileLibrary.vue";
const router = useRouter ()
const route = useRoute ()
const sub_text = ref('发布')
const categorys=ref('')
const tag = ref('')
const isSecret = ref(false)
const drawer = ref(false)
const RefreshPic=ref('')
const EditorInsert=ref('')
const data=reactive({
  short_content:'',
  article_category:'',
  tags:[],
  content:'',
  words: '0',
  characters: '0',
  read_time:'0',
  password:'',
  is_published:true,
  important_level:'通知',

})
const ShowimportLevel=ref(false)
const position=ref('rtl')
const size=ref('50%')
//响应式设置窗口弹出位置
if(window.innerWidth<768){
  position.value='ttb'
  size.value='60%'
}



//提交文章
const ruleFormRef = ref(typeof FormInstance)


const rules = reactive({
  content: [
    {required: true,message:"请输入文章内容"},
  ],
})
getCategoryList().then((res) => {
  categorys.value= res.data
})

const submit =  (formEl,type='发布' ) => {
  data.article_category=categorys.value.value
  //分类若为时间线线弹出重要程度选择框
  const category = categorys.value.filter(item => item.id == data.article_category)
  if(category[0]){
  if (category[0].articleCategoryName === '时间线日志') {
    ShowimportLevel.value = true
    if(data.important_level===''){
      ElMessage.error('请选择重要程度')
      return false
    }
    if(data.important_level==='通知'){
      data.important_level=1
    }
  }
  else {
    data.important_level=1
  }
      }
  else{
    data.important_level=1
  }

  //保存草稿
  if (type=='存入草稿'){
    data.is_published=false
    data.article_category=categorys.value.filter(item => item.articleCategoryName == '草稿箱')[0].id
    sub_text.value='存入草稿箱'
  }
  if(!data.short_content){
    data.short_content= convert(data.content.substring(data.content.indexOf('</h1>')),{wordwrap:25}).replace(/\s*/g,"").slice(0,25)
  }
  if (!formEl) return
   formEl.validate((valid) => {
    if (valid) {


      if(route.fullPath.indexOf('update')!=-1) {
        if(data.is_published==false && type!='存入草稿'){
          data.is_published = true
          data.article_category=''
        }
        UpdateArticle(data,route.params.id).then((res) => {
          if (res.status == 200) {
            ElMessage.success(sub_text.value+'成功' )
            router.push({path: '/article/' + res.data.id})
          }
        }).catch((err) => {
          ElMessage.error(err.response.data.detail,)
        })
      }
      else {
        SubmitArticle(data).then((res) => {
          if (res.status == 201) {
            ElMessage.success(sub_text.value + '成功')
            router.push({path: '/article/' + res.data.id})
          }

        }).catch((err) => {
          ElMessage.error(err.response.data.detail,)
        })
      }
    } else {
      ElMessage.error('文章或简介不能为空')
      return false
    }
  })
}
//文章更新
if(route.fullPath.indexOf('update')!=-1){
  sub_text.value='更新'
  const id=route.params.id
  getArticleDetail(id).then((res) => {
    data.short_content=res.data.short_content
    if(res.data.article_category){
      data.article_category=String(res.data.article_category)
    }
    document.title = res.data.title + '文章更新'
    data.tags=res.data.tags
    data.content='<h1>'+res.data.title+'</h1>'+res.data.content
    data.words=res.data.words
    data.characters=res.data.characters
    data.read_time=res.data.read_time
    data.is_published=res.data.is_published
    data.important_level=String(res.data.important_level)
    getCategoryList().then((res1) => {
      categorys.value.value=res1.data.filter(item => item.id == data.article_category)[0].id
      const category_name = res1.data.filter(item => item.id == data.article_category)[0].articleCategoryName
      if(category_name) {
        if (category_name === '时间线日志') {
          ShowimportLevel.value = true
        }
        else{
          data.important_level=1
        }
      }
    })

  })
}
//设置密码
const setPwd = () => {
  if (isSecret.value) {
    data.article_category = categorys.value.filter((item) => item.articleCategoryName == "加密内容")[0]['id']
    ElMessageBox.prompt('请设置加密文章密码', '加密文章', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      inputPattern: /^[a-zA-Z0-9]{6,16}$/,
      inputErrorMessage: '密码为6-16位字母或数字',
    })
        .then(({value}) => {
          data.password = value
          ElMessage({
            type: 'success',
            message: `密码成功设置为:${value}`,
          })
        })
        .catch(
            () => {
              isSecret.value = false
              data.article_category = ''

              ElMessage({
                type: 'error',
                message: '取消设置密码',
              })
            }
        )
  }
  else {
    data.article_category = ''
    data.password = ''
    ElMessage({
      type: 'error',
      message: '密码已取消',
    })
  }
}
//分类为时间线的时显示重要程度
const SelectTimeline = (val) => {
  data.article_category=categorys.value.value
  const category = categorys.value.filter(item => item.id == val)
  if(category[0]) {
    if (category[0].articleCategoryName == '时间线日志') {
      ShowimportLevel.value = true
    } else {
      ShowimportLevel.value = false
    }
  }
}
//刷新图片库内容
const RefreshPicLib=()=>{
  drawer.value=true

  try {
    RefreshPic.value.GetFileList()
  }
  catch (e) {
    console.log(e)
  }
}
//图片库通过父组件调用子组件富文本API
const SetPicurl=(html)=>{
    EditorInsert.value.insertPic(html)
}
</script>
<style scoped>

</style>
