<template>
  <dv-border-box-13 id="Rank">
    <dv-decoration-10 style="width:95%;height:5px;margin-left: 5px" />

    <dv-capsule-chart :config="config1" style="max-width: 90%;" />


  </dv-border-box-13>

</template>

<script setup>
import {ref} from "vue";

const config1=ref({
  data: [
    {
      name: '南阳',
      value: 167
    },
    {
      name: '周口',
      value: 123
    },
    {
      name: '漯河',
      value: 98
    },
    {
      name: '郑州',
      value: 75
    },
    {
      name: '西峡',
      value: 66
    },
  ],
  showValue: true
})


</script>

<style scoped>

</style>