<template>

  <el-col :xs="12" :sm="12" :md="6" v-for="o in items" :key="o.id" style="  text-align: center;">

    <el-space direction="vertical" :size="40 ">
      <el-card class="box-card" shadow="hover" >
        <template #header>
          <div class="card-header">
            <span>{{o.id}}</span>
          </div>
        </template>
        <div class="text item">{{ o.content}}</div>
      </el-card>
    </el-space>
  </el-col>
  <div style="text-align: center;width: 100%">
  <el-pagination background layout="prev, pager, next,jumper " @current-change="getGoods"	 :hide-on-single-page="false"	 :total="count" />
  </div>
</template>

<script setup>

import {getGoodsList} from "@/network/goods";
import {ref} from 'vue'
import {page_info} from "@/composables/pagination";

let items =ref([])
let count=ref(0)

const  getGoods  = (page)=> getGoodsList(page,page_info.size).then((res) => {
  items.value=res.data.results
  count.value=res.data.count
})

getGoods()
</script>

<style scoped>

</style>