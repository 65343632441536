<template>
    <el-popover  >
      <template   #reference>
      <span   @click="ShowLogin = true" id="login" >
        登录
      </span>

      </template>
      <template #default>
      <div style="text-align: center;">
        <P>登陆后您可以:</P>
        <P>记录日程计划</P>

        <el-button :round="true"  @click="ShowLogin = true" type="primary">立即登录</el-button>
      </div>
      </template>
    </el-popover>

  <el-dialog style="background-image: linear-gradient(to top, #f3e7e9 0%, #e3eeff 99%, #e3eeff 100%);z-index: 11 " v-model="ShowLogin" width="450px" title="登录/注册"   align-center >
<UserLogin v-model:ShowLogin="ShowLogin"/>
  </el-dialog>

</template>

<script setup>
import {ref} from "vue";
import UserLogin from "@/components/main/index/UserLogin";
const ShowLogin = ref(false)
// 判断逻辑待优化

</script>

<style scoped>
#login{
  margin-top: 12px;
  background-color: greenyellow;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center
}

</style>