<template>
<!--评论模块-->
  <div style="width: 100%;margin-top: 100px">
  <p class="comment_text">评论 <span class="total_comemnts">{{total_comments}}</span></p>
    <Toolbar
        :editor="editorRef"
        :defaultConfig="toolbarConfig"
        :mode="mode"
    />
    <Editor
        style="border: 1px solid #ccc ;background-color: #f7f9fb;height: 100px"

        :defaultConfig="editorConfig"
        :mode="mode"
        v-model="data.content "
        @onCreated="handleCreated"
    />
    <div style="text-align: right;margin-top: 10px">
      <el-button @click="Submit('')" type="primary" >
        发布
      </el-button>
    </div>
<!--  评论区-->
    <el-divider></el-divider>
    <div v-for="comment in comments" :key="comment.id" >
      <span>
      <el-avatar
          src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
      />
        </span>
      <span style="color: #61666d;cursor: pointer">{{comment[0].username}}:</span>
      <p style="padding-left: 40px" v-html="comment[0].content" >
      </p>
      <div>
      <small style="color: #9499A0;padding-left: 40px"> {{new(Date)(comment[0].created_at).toLocaleString()}}</small>
        <el-button @click="show_reply(comment[0].id,comment[0].id)" style="padding-left: 10px" link :size="small">
          <el-icon><ChatDotRound /></el-icon>
          <small>回复</small>
        </el-button>

      </div>

      <div v-if="comment.length>1&&ShowParentComments[comment[0].id] " >
    <div v-for="reply in comment.slice(1,)" :key="reply.id" :class="reply.level>=2?'child_reply':'reply'">
      <p v-if="reply.level>1" style="color: #61666d;">
       <span style="font-size: 13px;cursor: pointer;"> {{reply.username}}  回复：</span>
       <el-link  :underline="false" type="primary">  @{{comment.filter(item=>item.id==reply.parent)[0].username}}
       </el-link>
        <span  style="margin-left: 10px;font-width: 400;" > {{reply.content.replace(/<[^<>]+>/g,"")}}</span>

      </p>

      <span v-if="reply.level==1" style="color: #61666d;margin-right: 12px">
              <el-avatar
                  src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
              />
        <span style="font-size: 13px;cursor: pointer;"> {{reply.username}}</span>
              <span  style="margin-left: 10px" > {{reply.content.replace(/<[^<>]+>/g,"")}}</span>

      </span>

      <div>

        <span>
        <small style="color: #9499A0;"> {{new(Date)(reply.created_at).toLocaleString()}}</small>
        <el-button @click="show_reply(comment[0].id,reply.id)" style="padding-left: 10px" link :size="small">
          <el-icon><ChatDotRound /></el-icon>
          <small>回复</small>
          </el-button>

        </span>

      </div>

    </div>
        <p @click="show_reply(comment[0].id)" style="color: #9499A0;    padding-left: 8px;font-size: 13px;">
          收起
          <el-icon><CaretTop /></el-icon>
        </p>

      </div>
      <div v-else>
        <span v-if="comment.length>1" @click="show_reply(comment[0].id)" style="color: #9499A0;    padding-left: 8px;font-size: 13px;">
          展开{{comment.length-1}}条回复
<el-icon><ArrowDown /></el-icon>        </span>
      </div>
      <div           v-if="ShowReplyInput[comment[0].id]">
      <el-input
          v-model="textarea"
          :rows="3"
          style="margin-top: 10px;margin-bottom: 10px"
          type="textarea"
          :placeholder="'回复 @'+comment[0].username"
      />
      <el-button @click="Submit(textarea)" type="primary" >
        发布
      </el-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import '@wangeditor/editor/dist/css/style.css' // 引入 css

import {onBeforeUnmount, ref, shallowRef,reactive,defineProps} from "vue";
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import {GetComment, SubmitComment} from "@/network/comment";
import {ElMessage} from "element-plus";

const mode=ref('simple')
const editorRef = shallowRef()
const props = defineProps(['article_id'])
const article=ref(props.article_id)
const toolbarConfig = {
  toolbarKeys : [
    'emotion',
    'codeBlock',
  ],
}
const editorConfig = {
  placeholder: '发布一条友善的评论吧',
  autoFocus :false,
}
const data=reactive({
  content:'',
  article:'',
  parent:'',

})
const total_comments=ref(0)
const comments=ref()
const ShowParentComments=ref({})
const textarea=ref('')
const ShowReplyInput=ref({})
const isFirst=ref(true)
const Getcomments=()=> {
  GetComment(article.value).then(res => {
    total_comments.value = res.data.total_comments
    comments.value = res.data.results
    if(isFirst.value){
    const parent_list = res.data.results.filter(item => item[0].parent == null)
    //父评论展开字典
    for (let i = 0; i < parent_list.length; i++) {
      ShowParentComments.value[parent_list[i][0].id] = false
      ShowReplyInput.value[parent_list[i][0].id] = false
    }
      }
    isFirst.value=false

  })
}
const show_reply=(id,reply_to)=>{
  if(reply_to){
    data.parent=reply_to
    ShowReplyInput.value[id]=!ShowReplyInput.value[id]
  }
  else{
    ShowParentComments.value[id]=!ShowParentComments.value[id]
  }
}
const Submit=(content='')=>{
  const editor = editorRef.value

  if(editor.isEmpty()&&textarea.value==''){
    ElMessage.error('评论内容不能为空')
    return
  }
  if(content){
    data.content=content
  }
  data.article=article.value
  SubmitComment(data).then(res=>{
    // 重置编辑器内容
    editor.clear()
    textarea.value=''
    if (res.status === 201) {
      ElMessage.success('评论成功')
    }

    Getcomments()
  })
}
// 组件销毁时，也及时销毁编辑器
onBeforeUnmount(() => {
  const editor = editorRef.value
  if (editor == null) return
  editor.destroy()
})
const handleCreated = (editor) => {
  editorRef.value = editor // 记录 editor 实例，重要！
}
Getcomments()
</script>

<style scoped>
.comment_text{
  font-weight: 600;
  font-size: large;
}
.total_comemnts{
  margin: 0 36px 0 6px;
  font-weight: normal;
  color: #9499A0
}
.reply{
  margin-left: 60px;
}
.child_reply{
  margin-left: 80px;
  background-color: #f7f9fb;

}
</style>