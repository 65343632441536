import {reactive} from "vue";


export let page_info =reactive({
    page:1,
    size:10,
    ordering:'-created_at',
    search:'',

})
