<template>


  <el-tabs
             :stretch="true"
             v-model="activeName"

  >
    <el-tab-pane  label="密码登录" name="密码登录">
      <el-form >
        <el-form-item label="账号">
          <el-input v-model="User"
                    size="large"
                    placeholder="请输入账号"
                    @change="login"

                    clearable  />
        </el-form-item>
        <el-form-item label="密码">
          <el-input
              v-model="Pwd"
              type="password"
              size="large"
              placeholder="请输入密码"
              show-password
              @change="login"

          >
            <template #append>
              <el-link :underline="false" type="warning">忘记密码？</el-link>

            </template>
          </el-input>
        </el-form-item>
        <div style="text-align: center">
          <el-button size="large" @click="onSubmit" style="margin: 20px" :round="true" >注册</el-button>
          <el-button  size="large" type="primary"  style="margin: 20px" :round="true" @click="login">登录</el-button>
        </div>
      </el-form>

    </el-tab-pane>
    <el-tab-pane label="短信登录" name="短信登录">
      <el-form >
        <el-form-item >
          <el-input v-model="User"
                    size="large"
                    placeholder="请输入手机号"
                    class="input-with-select "
                    clearable  >
            <template #prepend>
              <el-select  size="large" placeholder="+86" style="width: 80px">
                <el-option label="Restaurant" value="1" />
                <el-option label="Order No." value="2" />
                <el-option label="Tel" value="3" />
              </el-select>
            </template>
            <template #append>
              <el-link :underline="false" :disabled="!CodeActive" @click="SendCode()" type="warning" style="width: 80px">{{CodeMessage}}</el-link>

            </template>
          </el-input>
        </el-form-item>

        <el-form-item >
          <el-input
              v-model="Pwd"
              type="password"
              size="large"
              placeholder="请输入验证码"
              show-password
          >

          </el-input>
        </el-form-item>
        <div style="text-align: center">
          <el-button size="large" @click="onSubmit" style="margin: 20px" :round="true" >注册</el-button>
          <el-button  size="large" type="primary"  style="margin: 20px" :round="true" @click="login">登录</el-button>
        </div>
      </el-form>

    </el-tab-pane>

  </el-tabs>

</template>

<script setup>
import {defineEmits, ref} from "vue";
import {SendCode,CodeActive,CodeMessage} from "@/network/userlogin";
import {UserLogin} from "@/network/user";
import {ElMessage} from "element-plus";
const User = ref("")
const Pwd=ref("")
const activeName=ref('密码登录')
const  emit =  defineEmits(['ShowLogin'])
import { useStore } from 'vuex'
const store = useStore()
const login=()=>{
  UserLogin({username:User.value,password:Pwd.value}).then(res=>{
    if (res.status==200) {
      store.commit('setToken',res.data.access)
      store.commit('setRefreshToken',res.data.refresh)
      emit('update:ShowLogin', false)
      ElMessage.success('登录成功')
      //刷新页面
      window.location.reload()

    }

  })

}
</script>

<style >
.input-with-select .el-input-group__prepend {
  background-color: var(--el-fill-color-blank);
}
</style>