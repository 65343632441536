<template>

  <dv-charts :option="option1" :key="key"  style="height: 600px;margin: 0 auto"/>
</template>

<script setup>
import {ref,onMounted,defineExpose } from "vue";
import {GetStocksRank} from "@/network/main";
const TenDays=ref([])
const key=ref(0)
const Top10=ref([])
const series=ref([])
const seriesList=ref([])
GetStocksRank().then(res=>{
  for(let i=0;i<res.data.Top_10.length;i++ ){
    Top10.value.push(res.data.Top_10[i])
  }

  const date=res.data.date
  for (let i=0;i<date.length;i++){
    let created_at=date[i].created_at
    //截取年月日
    TenDays.value.push(created_at.slice(0,10))
  }
  for (let i=0;i<Top10.value.length;i++){
    let name=Top10.value[i]
    for (let j=0;j<res.data.options[i]['value'].length;j++){
      seriesList.value.push(res.data.options[i]['value'][j])
    }

    series.value.push({
      name: name,
      data: seriesList.value,
      smooth: true,
      type: 'line',
      linePoint: {
        radius: 4
      },
      label: {
        show: true,
        formatter :name+' {value}',
        style: {
          fontSize: 15
        },
        lineStyle: {
          lineWidth: 1
        }
      }


    })
    seriesList.value=[]
  }
})
const option1 = {
  title: {
    text: '板块轮动'
  },
  legend: {
    data: Top10.value
  },
  xAxis: {
    name: '最近10次',
    data: TenDays.value
  },
  yAxis: {
    name: '板块热度(每天11:30,15：00更新)',
    data: 'value'
  },
  series: series.value
}
defineExpose({
  key
})

onMounted(()=>{
  GetStocksRank()

})
</script>

<style scoped>

</style>