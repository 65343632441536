<template>
    <el-button type="danger" @click="buttonClick(0)">采煤机</el-button>
    <el-button type="primary" @click="buttonClick(1)">刮板机</el-button>
    <el-button type="success" @click="buttonClick(2)">液压支架</el-button>
    <dv-scroll-board :config="selectdata()" style="width: 95%; height: 50%;margin: 20px"  />


</template>

<script setup>
import { ref} from 'vue'
import mitts    from "@/composables/brotherCom";
import {getBiShe} from "@/network/main";
let data1=ref()

getBiShe('bottom').then(res => {
    data1.value=res.data['data1']
    config.data =res.data['data1']
    config1.data=res.data['data2']
    config2.data=res.data['data3']
})

//采煤机
const nowdata=ref(0)
const buttonClick = (num) => {
    nowdata.value=num
    mitts.emit('change',num)
}
const selectdata=()=>{
    if(nowdata.value==0){
        return config
    }else if(nowdata.value==1){
        return config1
    }else if(nowdata.value==2){
        return config2
    }
    return config.value
}

const config= {
    data: data1,
    header: ['振动数据', '行走速度', '工作面位置', '电机电流-电压', '刀盘转速', '摇臂高度', '电机温度'],
    waitTime: 1000,
}
const config1={
    header: ['振动数据', '刮板机滚筒转速','电机电流-电压','电机温度'],
    data:[],

}
const config2={
    header:['液压油位','液压油温','液压油压','液压油流量','位置','电机电流-电压'],
    data:[]
}

</script>

<style scoped>

</style>