<template>
    <div class="left-chart-1">
        <div class="lc1-header">采煤机
            <el-icon v-if="firstWarning" @click="centerDialogVisible = true" class="message" size="22" color="red"><WarningFilled /></el-icon>
            <el-dialog
                v-model="centerDialogVisible"
                title="警告！一号采煤机数据异常"
                width="30%"
                align-center
                center
            >
                <span>
                    <p>
                  <el-text size="large">
                        电机温度：100℃
                    </el-text>
                    </p>

                    <el-text size="large">
                        异常时间：2021-05-01 12:00:00
                    </el-text>

                </span>
                <template #footer>
      <span class="dialog-footer">
        <el-button type="danger" @click="centerDialogVisible = false;firstWarning=false">
          检修
        </el-button>
      </span>
                </template>
            </el-dialog>
        </div>
        <div v-if="result1" class="lc1-details">设备总数<span class="span1">{{ result1['one']['total'] }}</span>
        正常总数<span style="color: green">{{ result1['one']['right'] }}</span>
        异常总数<span style="color:red">{{ result1['one']['wrong'] }}</span>
        </div>
        <div v-if="result1" class="lc1-details">正在运行<span class="span1">{{ result1['two']['running'] }}</span>
            正常<span style="color: green">{{ result1['two']['right'] }}</span>
            异常<span style="color:red">{{ result1['two']['wrong'] }}</span>
        </div>
        <div v-if="result1" class="lc1-details">暂停运行<span class="span1">{{ result1['three']['stopping'] }}</span>
            正常<span style="color: green">{{ result1['three']['right'] }}</span>
            异常<span style="color:red">{{ result1['three']['wrong'] }}</span>
        </div>
        <dv-decoration-2 style="height:10px;" />


        <div class="lc1-header">液压支架

        </div>
        <div class="lc1-details">设备总数<span class="span1">200</span>
            正常总数<span style="color: green">200</span>
            异常总数<span style="color:red">0</span>
        </div>
        <div class="lc1-details">正在运行<span class="span1">100</span>
            正常<span style="color: green">100</span>
            异常<span style="color:red">0</span>
        </div>
        <div class="lc1-details">暂停运行<span class="span1">100</span>
            正常<span style="color: green">100</span>
            异常<span style="color:red">0</span>
        </div>
        <dv-decoration-2 style="height:10px;" />
        <div class="lc1-header">刮板机

        </div>
        <div class="lc1-details">设备总数<span class="span1">4</span>
            正常总数<span style="color: green">3</span>
            异常总数<span style="color:red">1</span>
        </div>
        <div class="lc1-details">正在运行<span class="span1">2</span>
            正常<span style="color: green">2</span>
            异常<span style="color:red">0</span>
        </div>
        <div class="lc1-details">暂停运行<span class="span1">2</span>
            正常<span style="color: green">1</span>
            异常<span style="color:red">1</span>
        </div>
        <dv-decoration-2 style="height:10px;" />

    </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {WarningFilled} from "@element-plus/icons-vue";
import {getBiShe} from "@/network/main";
const centerDialogVisible = ref(false)
const firstWarning = ref(true)
const result1 = ref()
const result2 = ref()
const result3 = ref()
onMounted(()=>{
    getBiShe('left').then(res => {
        result1.value = res.data['data1']
        result2.value = res.data['data2']
        result3.value = res.data['data3']
    })
})

</script>

<style scoped>
.left-chart-1 {
    width: 100%;
    display: flex;
    flex-grow: 0;
    flex-direction: column;

}
.message {
    animation: blink 0.5s linear infinite;
}

@keyframes blink {
    50% {
        opacity: 0.5;
    }
}

.lc1-header {
    text-align: center;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    margin-bottom: 20px;
}
.lc1-details {
    height: 50px;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-indent: 20px;

}
.lc1-details> .span1 {
    color: #096dd9;
    font-weight: bold;
    font-size: 35px;
    margin-left: 20px;
}


.lc1-chart {
    flex: 1;
}
</style>