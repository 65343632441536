<template>
  <div>
    <el-autocomplete
        style="margin: 20px;"
        v-model="state"
        :fetch-suggestions="querySearchAsync"
        placeholder="请输入文章的标题,内容,分类"
        @select="SearchResultSelect"
        value-key="title"
        clearable
    />
    <el-button :disabled="ids.length==0&&MultiSelect=='批量删除'?true:false"
               @click="handleMultiSelect" :type="MultiSelect=='批量删除'?'danger':'info'"
               style="margin-left: 8px; " >
      {{MultiSelect}}
    </el-button>
    <el-tooltip
        effect="dark"
        content="刷新界面"
        placement="right"
    >
    <el-icon style="margin-left: 10px;margin-top: 10px" :size="16" @click="refresh"><Refresh /></el-icon>
    </el-tooltip>
    <el-alert v-if="ids.length>0"  type="success" show-icon >
    <template #title>
      <span style="font-size: medium">已选择{{ids.length}}条</span>
        <el-link @click="handlCleanSelects" type="primary" :underline="false" style="margin-left: 5px;font-size: medium">清除</el-link>
    </template>
    </el-alert>

    <el-table  :data="articles"
            :default-sort="{ prop: 'created_at', order: 'descending' }"
            @sort-change="handleSortChange"
            @selection-change="handleSelectionChange"
            v-loading="loading"
            @filter-change="filterCategory"
             ref="multipleTableRef"


               style="width: 100%">
    <el-table-column v-if="MultiSelect=='批量删除'" type="selection" width="55" />
    <el-table-column  label="标题" sortable="custom" >
      <template #default="scope">
        <el-link :underline="false" :href="'/article/'+scope.row.id">{{scope.row.title}}</el-link>
      </template>
    </el-table-column>
    <el-table-column prop="short_content" label="简介" show-overflow-tooltip />
    <el-table-column  label="分类"
                      :filters="categoryList"
                      filter-placement="bottom-end"
    >
    <template #default="scope">
      <el-tag type="warning" disable-transitions v-if="handleCategory(scope.row.article_category)" >
        {{handleCategory(scope.row.article_category)}}
      </el-tag>
    </template>
    </el-table-column>
      <el-table-column prop="read_count" show-overflow-tooltip sortable   label="浏览量"/>
    <el-table-column prop="created_at" label="发布时间" sortable="custom" />
    <el-table-column label="操作" min-width="100px">
      <template #default="scope">
        <el-link  style="padding: 6px 10px" :underline="false" @click="$router.push('/article/update/'+scope.row.id)">
          <el-button type="success" bg text 
          >
            编辑</el-button
          >
        </el-link>

        <el-button
            type="danger"
            style="padding: 6px 10px"
            @click="handleDelete( scope.row)"
        >删除</el-button
        >
      </template>
    </el-table-column>
  </el-table>

  </div>
  <p style="text-align: center;width: 100%">

    <el-pagination
        :hide-on-single-page="true"
        layout="total, sizes, prev, pager, next, jumper"
        :total="count"
        background
        style="margin: 0 auto"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :page-sizes="[10, 20, 50, 100]"
    />

  </p>

</template>

<script setup>
import {ref } from "vue";
import {DeleteArticle, getArticleList, getCategoryList} from "@/network/article";
import {page_info} from "@/composables/pagination";
import { ElNotification,ElMessageBox  } from 'element-plus'
import { ElTable } from 'element-plus'

const articles=ref('')
const category=ref()
const categoryList=ref([])
const SelectCategory=ref()
const loading = ref(true)
const count=ref(0)
const state=ref('')
const MultiSelect=ref('多选')
const ids=ref([])
const multipleTableRef=ref(typeof ElTable)
getCategoryList().then(res=>{
 category.value=res.data
  category.value.map((item)=>{
    categoryList.value.push({text:item.articleCategoryName,value:item.articleCategoryName})
  })
})
const getArticles = ()=> getArticleList(page_info,SelectCategory.value).then(res=>{
  articles.value=res.data.results
  loading.value=false
  count.value=res.data.count
})
//翻页
const handleCurrentChange = (page)=> {
  page_info.page=page
  getArticles()
}
const handleSizeChange = (size)=> {
  page_info.size=size
  getArticles()
}
//批量删除
const handleMultiSelect = ()=>{
  if(MultiSelect.value!='多选'&&ids.value.length>0){
      ElMessageBox.confirm(
          '此操作将永久删除，是否继续?',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'error',
          }
      ).then(() => {
        DeleteArticle(ids.value[0], ids.value).then(() => {
          ElNotification({
          title: '删除成功',
          message: '文章已删除',
          type: 'success',
        })
        getArticles()
      })
    })
  }
  MultiSelect.value='批量删除'


}
const handleSelectionChange = (val)=> {
  ids.value=[]
  for (var i = 0; i < val.length; i++) {
    ids.value.push(val[i].id)
  }

}
const handlCleanSelects = ()=>{
  ids.value=[]
  multipleTableRef.value.clearSelection()
}
//删除文章
const handleDelete = (row)=> {
    ElMessageBox.confirm(
        '此操作将永久删除，是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'error',
        }
    ).then(() => {
      DeleteArticle(row.id).then(() => {
        ElNotification({
        title: '删除成功',
        message: '文章已删除',
        type: 'success',
      })
      getArticles()
    })

  })
}
//搜索建议

const querySearchAsync = (query, cb)=> {
  getArticleList({search:query}).then(res=>{
    articles.value=res.data.results
    loading.value=false
    count.value=res.data.count
        cb(res.data.results)
  })

}
const SearchResultSelect = (item)=> {
  articles.value=[item]
}
const refresh = ()=> {
  MultiSelect.value='多选'
  getArticleList({search:''}).then(
      res=>{
        state.value=''
        articles.value=res.data.results
      }
  )
}
//显示分类
const handleCategory=(id)=>{
  if (id&&category.value){
    if (category.value.filter(item=>item.id==id).length>0){
      return category.value.filter(item=>item.id==id)[0].articleCategoryName
    }
  }
}
//筛选过滤
const filterCategory=(names)=>{
  SelectCategory.value=Object.values(names)
  page_info.page=1
  getArticles()
}
//排序，分类
const handleSortChange = ({ column }) => {
  //浏览量暂时不使用服务器排序
  if (column.label != '浏览量') {
    if (column.order === 'descending') {
      if (column.label == '发布时间') {
        page_info.ordering = '-created_at'
      } else if (column.label == '标题') {
        page_info.ordering = '-title'
      }
    } else {
      if (column.label == '发布时间') {
        page_info.ordering = 'created_at'
      } else if (column.label == '标题') {
        page_info.ordering = 'title'
      }
    }
    getArticles()
  }
}

getArticles()
</script>

<style scoped>

</style>