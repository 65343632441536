import 'element-plus/theme-chalk/display.css'
import DataVVue3 from '@kjgl77/datav-vue3'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/dark/css-vars.css'

import { createApp, } from 'vue'
import ElementPlus from 'element-plus'
import App from './App.vue'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import router from './router'

import {store} from "@/store";

const app = createApp(App)
//网站标题


for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.use(ElementPlus)
app.use(router).mount('#app')
app.use(DataVVue3)
app.use(store)
