import {ref} from "vue";

export  const category_name_map=ref({
    'article':'文章',
    'project':'项目',
    'time_log':'时间轴',
    'book':'书籍',
    'music':'音乐',
    'movie':'电影',
    'game':'游戏',
    'tool':'工具',
    'other':'其他',
    'goods':'商品',
})