<template>
    <div class="center-cmp">
        <div class="cc-header">
            <dv-decoration-1 style="width:200px;height:50px;" />
            <div style="color: red;">一号工作面

            </div>
            <el-select v-model="value1.value" style="float: right;width: 150px"  placeholder="请选择工作面" size="large">
                <el-option
                    v-for="item in value1"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                />
            </el-select>
            <dv-decoration-1 style="width:200px;height:50px;" />
        </div>


        <div class="cc-main-container">
            <div class="ccmc-left">
                <div class="station-info">
                    刮板机
                    <div v-for="(i,j) in result1" :key="i">
                        <p>{{j+':'+i}}</p>
                    </div>
                </div>

            </div>

            <dv-decoration-2 :reverse="true" style="width:5px;height:150px;" />

            <div class="ccmc-right">
                <div class="station-info">
                    采煤机
                    <div v-for="(i,j) in result2" :key="i">
                       <p>{{j+':'+i}}</p>
                    </div>
                </div>
            </div>
            <dv-decoration-2 :reverse="true" style="width:5px;height:150px;" />

            <div class="ccmc-right">

            <div class="station-info">
                    <div class="station-info">
                        液压支架
                        <div v-for="(i,j) in result2" :key="i">
                            <p>{{j+':'+i}}</p>
                        </div>

                            <span>支架编号</span>  :<el-slider v-model="valueGua" range :step="10" show-stops   />


                    </div>
            </div>
</div>


            <LabelTag :config="labelConfig" />
        </div>
    </div>
</template>

<script setup>
import {ref,onMounted} from 'vue'
import {getBiShe} from "@/network/main";
const result1=ref()
const result2 = ref()
const result3 = ref()
onMounted(()=>{
    getBiShe('center').then(res => {
        result1.value = res.data['guaban']
        result2.value = res.data['caimei']
        result3.value = res.data['yeya']
    })
})

const labelConfig= {
    data: ['收费站', '监控中心', '道路外场', '其他']
}
const valueGua = ref(0)
const value1 = ref([
    {
        value: 'Option1',
        label: '一号工作面',
    },
    {
        value: 'Option2',
        label: '二号工作面',
    },
    {
        value: 'Option3',
        label: '三号工作面',
    },
])
</script>

<style scoped>
.center-cmp {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    .cc-header {
        height: 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 30px;
    }

    .cc-main-container {
        position: relative;
        flex: 1;
        display: flex;
        .ccmc-middle {
            margin-top: 10px;
            width: 100%;
            height: 100%;
            .active-ring-name {
                font-size: 20px !important;
            }
        }
        .ccmc-left, .ccmc-right {
            width: 30%;
            flex-direction: column;
            font-size: 22px;


        }
        .ccmc-left {
            align-items: flex-end;

        }
        .ccmc-right {
            align-items: flex-start;

        }
    }
    .label-tag {
        position: absolute;
        width: 500px;
        height: 30px;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
    }
}
</style>