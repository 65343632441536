<template>
  <el-result title="404" sub-title="页面被妖怪抓走了">
    <template #icon>
      <el-image
          :src="require('@/assets/404.gif')"  fit='contain' style="max-width: 600px;height: 300px"
      >
      </el-image>
    </template>
    <template #extra>
      <el-link :underline="false" href="/" >
        <el-button   type="primary"  plain>回到首页</el-button>
      </el-link>
    </template>
  </el-result>
</template>

<script setup >
</script>
<style scoped>

</style>