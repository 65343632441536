import { request } from './request'
import {ElMessage} from "element-plus";

export function getCategoryList() {
    return request({
        url: "/main/categorylist",
    })

}
export function getTimeLogList() {
    return request({
        url: "/main/timelinelog",
    })

}
export function GetSiteInfoData(charts_type='') {
    return request({
        url: "/main/siteinfo",
        params: {
            charts_type: charts_type
        }
    })
}
export function GetSiteSettings() {
    return request({
        url: "/main/sitesettings",
    })
}
export function GetStocksRank() {
    return request({
        url: "/main/stocksrank",
    })
}
export function DeleteCarousel(data) {
    return request({
        url: "/main/sitesettings/",
        method: 'delete',
        data: data
    })
}
export function UpdateSiteSettings(data,id) {
    return request({
        url: "/main/sitesettings/"+id+"/",
        method: 'patch',
        data: data
    })
}
export function AddChanlunStocks(data) {
    return request({
        url: "/main/chanlun/",
        method: 'post',
        data:data
    })
}
export function UpdateChanlunStocks(data,id) {
    return request({
        url: "/main/chanlun/"+id+"/",
        method: 'patch',
        data:data
    })
}
export function getChanlunStocks(page_info) {
    return request({
        url: "/main/chanlun/",
        method: 'get',
        params: page_info
    })
}
export function getChanlunBaseinfo(page_info) {
    return request({
        url: "/main/chanlun/baseinfo/",
        method: 'get',
        params: page_info
    })
}
export function getChanlunExport() {
    return request({
        url: "/main/chanlun/export/",
        method: 'get',
        responseType: 'blob', // Ensure the response is handled as a Blob
    }).then(response => {
        // Create a URL for the blob object
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
        // Create a link element
        const link = document.createElement('a');
        link.href = url;

        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // 月份从0开始
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        const filename = `chanlun_export_${year}${month}${day}_${hours}${minutes}${seconds}.csv`;
        link.setAttribute('download', filename); // Set the file name for download
        // Append the link to the body and trigger click to start the download
        document.body.appendChild(link);
        link.click();
        // Clean up
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
        ElMessage({
            message: '导出报表成功',
            type: 'success',
        })
    });
}
export function getBiShe(type) {
    return request({
        url: "/main/bishe/",
        method: 'get',
        params: {'type':type},
    })
}
