<template>
  <el-icon v-if="theme" :size="30"  style="position: absolute; left: 12%;top:5%;z-index: 111" @click="toggledark"><Sunny/> </el-icon>
  <el-icon v-else :size="30"  style="position: absolute; left: 12%;top:5%;z-index: 111"  @click="toggledark"><Moon/> </el-icon>

  <router-link  style="position: absolute; left: 2%;top:5%;z-index: 111" to>
    <el-icon :size="30" @click="$router.go(-1)" ><Back /></el-icon>
  </router-link>

  <el-icon  :size="30" color="lightgreen" v-if="fullscreen"  @click="fullScreen()" style="position: absolute; left: 25%;top:5%;z-index: 111" circle>
    <FullScreen/>
  </el-icon>
  <el-icon :size="30" v-else color="red"  @click="fullScreen()" style="position: absolute; left: 25%;top:5%;z-index: 111" circle>
    <SwitchButton/>
  </el-icon>
</template>

<script setup>
import {ref} from "vue";
import screenfull from "screenfull";
import {toggleDark} from "@/composables/dark";

const theme=ref(true)
const fullscreen=ref(true)

const fullScreen  = ()=> {
  fullscreen.value = !fullscreen.value
  screenfull.toggle()

}
const toggledark = ()=> {
  theme.value = !theme.value
  return toggleDark()
}

</script>

<style scoped>

</style>