import axios from 'axios'
import {ElNotification,ElMessage} from "element-plus";
import {ApiUrl} from "@/network/config";

export function request(config) {
    const instance1 = axios.create({
        baseURL:ApiUrl ,
        timeout: 3000,
        retry: 4,
        retryDelay: 1000,
        HasSuccess: false,
    })
    //请求拦截和响应拦截
    instance1.interceptors.request.use(config => {
            //获取前端token
        if(localStorage.getItem('token')){
            config.headers.Authorization ='Bearer '+ localStorage.getItem('token')
        }
        return config;
    }, err => {
        console.log(err);
    })
    instance1.interceptors.response.use(res => {
        if(res.config.HasSuccess){
            ElMessage({
                message: "服务器重连成功",
                type: 'success',
                grouping: true,
            })
        }
        return res;

    },err => {
        try {
            if (err.response.status == 400) {
                for (let i in Object.values(err.response.data)) {
                    ElMessage.error(
                        Object.values(err.response.data)[i][0],)
                }
            }

        }catch (e) {
            // 超时重连

            var config = err.config;
            if (config.method == 'get') {
                config.HasSuccess = false;
                if (!config || !config.retry) return Promise.reject(err);
                config.__retryCount = config.__retryCount || 0;
                if (config.__retryCount >= config.retry) {
                    ElMessage.error("服务器重连失败，请刷新后重新尝试")
                    return err;
                }
                config.__retryCount += 1;
                ElMessage({
                    message: '服务器响应超时,正在重新尝试(' + '第' + config.__retryCount + '次' + ')',
                    type: 'error',
                    grouping: true,
                })
                var backoff = new Promise(function (resolve) {
                    setTimeout(function () {
                        resolve();
                    }, config.retryDelay || 1);
                });
                config.HasSuccess = true;

                // Return the promise in which recalls axios to retry the request
                return backoff.then(function () {

                    return instance1(config);
                });
            }
            else {
                ElMessage.error("服务器连接失败，请刷新后重新尝试")
                return err;
            }
        }
        if (err.response.status==401) {
            if(localStorage.getItem('token')){
                localStorage.removeItem('token')
                ElMessage.error('登录身份已过期，请重新登录')

            }
            else {
                ElMessage.error('身份认证失败')
            }
            return err
        }
            if (err.response.status==0) {
            ElMessage({
                message: '网络或服务器异常',
                type: 'error',
                offset: 60,
            })
            return err
        }
            if (err.response.status==500) {
            ElNotification({
                title: '错误',
                message: "服务器出错啦",
                type: 'error',
            })
            return Promise.reject(err)
        }
        if (err.response.status==502) {
            ElNotification({
                title: '错误',
                message: "服务器请求失败",
                type: 'error',
            })
            return Promise.reject(err)
        }
        if (err.response.status==404) {
            return 0
        }
            })
    return instance1(config);
}
