<template>
  <el-row>
    <el-space wrap>
      <el-button @click="item_class" round>全部</el-button>
    <el-button v-for="i in category" :key="i.id" @click="item_class" round>
      {{ i.articleCategoryName }}({{i.count}})
    </el-button>

    </el-space>
  </el-row>
</template>

<script setup>
//头部分类筛选
import { defineEmits,ref } from 'vue'
import { getCategoryList} from "@/network/article";
const emit = defineEmits(['item_class'])
const item_class=(rel)=>{

  let param =rel.currentTarget.innerText.split('(')[0]


//传递给父组件
  emit('item_class',param)
}
const category = ref('')
getCategoryList().then((res) => {
  category.value=res.data
})

</script>


<style scoped>

</style>