<template>

  <dv-full-screen-container>
    <dv-loading v-if="loading">Loading...</dv-loading>

    <dv-border-box-11  title="爱尚购数据统计">

      <el-row  justify="space-around">

        <el-col class="site_info" :xs="12" :sm="12" :md="7">
        <SIteHeader/>
        <RankPage style="height: 33%"/>
          <dv-border-box-12 style="height: 33%">
            <CategoryList/>
          </dv-border-box-12>
          <dv-border-box-12>
2
        </dv-border-box-12>
      </el-col>
      <el-col class="site_info" :xs="12" :sm="12" :md="9"	>
        <BaseInfo/>
      </el-col>
      <el-col class="site_info" :xs="12" :sm="12" :md="7"	>
    <dv-border-box-1>
      <dv-scroll-board :config="config" style="width:500px;height:220px" />

    </dv-border-box-1>

      </el-col>
    </el-row>

      </dv-border-box-11>

  </dv-full-screen-container>



</template>

<script setup>
import {ref} from "vue";
import RankPage from "@/components/main/SiteInfo/RankPage";
import BaseInfo from "@/components/main/SiteInfo/BaseInfo";
import SIteHeader from "@/components/main/SiteInfo/SIteHeader";
import CategoryList from "@/components/main/SiteInfo/CategoryList";
const loading = ref(true)
setTimeout(() => {
  loading.value = false
}, 100)
</script>

<style scoped>
.site_info{
  margin-top: 60px;
}

</style>