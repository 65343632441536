<template>
  <div>
    <el-container >

      <el-header v-if="!$route.meta.NoNav">
        <BaseNav :SwitchLanguage="SwitchLanguage"/>
      </el-header>

      <el-config-provider :locale="locale">

        <router-view v-if="$route.meta.title=='首页'"/>

        <el-main v-else  >
          <router-view v-if="$route.meta.setScroll" />
          <router-view v-else />
        </el-main>
      </el-config-provider>

      <!--      必须在main之后-->
      <el-backtop target=".el-scrollbar__wrap" ></el-backtop>
      <el-footer  v-if="!$route.meta.NoNav">

        <BaseFooter/>
      </el-footer>

    </el-container>


    <!--数据统计页     以及不需要默认展示导航栏页脚的页面-->
    <transition v-if="$route.meta.SiteInfo&&$route.meta.NoNav" name="el-zoom-in-bottom">
      <router-view ></router-view>
    </transition>
  </div>
</template>

<script setup>
import BaseFooter from "@/components/BaseFooter";
import BaseNav from "@/components/BaseNav";
import {computed, ref} from "vue";

import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import en from 'element-plus/dist/locale/en.mjs'
const language = ref('zh-cn')


const locale = computed(() => (language.value === 'zh-cn' ? zhCn : en))
const SwitchLanguage=()=>{
  language.value = language.value === 'zh-cn' ? 'en' : 'zh-cn'
}
</script>

<style scoped>

</style>