<template>
  <el-carousel :interval="4000" type="card" height="200px">
    <el-carousel-item v-for="pic in pics" :key="pic.id" >
      <el-image :src="pic.url+'?'+time" :alt="pic.name" fit="fill" >
        <template #error>
          {{pic.name}}
        </template>
      </el-image>
    </el-carousel-item>
  </el-carousel>
</template>

<script setup>
import { ref } from 'vue'
import {GetSiteSettings} from "@/network/main";

const pics=ref()
GetSiteSettings().then(res=>{
  pics.value=res.data[0]['carousel']
})
//随机时间戳后缀
const time=new Date().getTime()

</script>

<style scoped>

</style>