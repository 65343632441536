<template>
  <el-col  :span="3" class="hidden-md-and-down">
<p v-for ="o in Object.keys(data)" :key="o">
  {{ o}}

</p>
<!--    加载前骨架-->
  </el-col>
    <el-col  v-if="loading" :xs="24" :sm="24" :md="21">
      <el-card  :body-style='{ padding: "0px" }'
                 class="card"
                 shadow="never"  >
        <template #header>
          <div class="card-header">
            <span>
                          <el-skeleton-item variant="text" />

            </span>

            <el-button type="info"  link >
                更多
                <el-icon><ArrowRight /></el-icon>
              </el-button>
          </div>
        </template>
    <el-row>
    <el-col v-for="i in 8" :key="i"  :xs="12" :sm="12" :md="6" style="  text-align: center;padding: 10px">
    <el-skeleton style="width: 100%" :loading="loading" animated>
      <template #template>
        <el-skeleton-item variant="image" style="width: 100%; height: 150px" />
        <div style="padding: 14px">
          <el-skeleton-item variant="h3" style="width: 100%" />
          <div
              style="
              display: flex;
              align-items: center;
              margin-top: 16px;
              height: 16px;
            "
          >
            <el-skeleton-item variant="text" style="margin-right: 16px" />
            <el-skeleton-item variant="text"/>

            <el-skeleton-item variant="text" style="width: 30%" />
          </div>
        </div>
      </template>
    </el-skeleton>
  </el-col>
    </el-row>
      </el-card>
    </el-col>
<!--  真正内容-->

  <el-col v-else :xs="24" :sm="24" :md="21">

    <el-card   :body-style='{ padding: "0px" }'
           class="card"
           v-for ="(item,title) in data" :key="item" shadow="never"  >
      <template #header>
        <div class="card-header">
          <span>
            {{ title }}</span>
          <el-link :underline="false" @click="$router.push('article')" >
          <el-button type="info"  link >
              更多
            <el-icon><ArrowRight /></el-icon>
          </el-button>
          </el-link>
        </div>
      </template>
      <el-row>

        <el-col  :xs="12" :sm="12" :md="6" v-for="i in item" :key="i.id" style="  text-align: center;padding: 10px">
          <el-card @click="$router.push('/article/'+i.id)"
                   class="card"
                   :body-style='{ padding: "0px" }'
                   style="height: 100%"
                   shadow="hover"


          >
            <el-image
                :src="i.cover_image"
                class="card-image"
                style="max-height: 100%;width: 100%"
                lazy
            />
                <p style="font-size: var(--el-font-size-extra-large)">
                  <strong>
                  {{i.title}}
                  </strong></p>
            <el-text style="margin: 10px" truncated>  {{i.short_content}} </el-text>
          </el-card>
        </el-col>

      </el-row>
    </el-card>
    <StocksRank class="card" ref="KeyChange" />

  </el-col>
</template>

<script setup>
import {getCategoryList} from "@/network/main";
import {ref} from "vue";
import StocksRank from "@/components/main/SiteInfo/StocksRank.vue";

const data = ref([])
const loading = ref(true)
getCategoryList().then((res) => {
  data.value=res.data
  loading.value=false
})
</script>

<style scoped>

.card-image{
  width: 100%;
}
.item {
  justify-content: space-between;
}
.el-main{
  --el-main-padding: 0;
}

</style>
<style>
.card{
  position: relative;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  backdrop-filter: blur(4px) saturate(180%);
  -webkit-backdrop-filter: blur(4px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 12px;
  border: 2px solid rgba(209, 213, 219, 0.3);
  width: 100%;
  opacity: 0.9;
  color: black;

}
</style>