<template>
  <el-upload
      v-model:file-list="fileList"
      list-type="picture-card"
      :on-remove="handleRemove"
      :on-preview="handlePictureCardPreview"
      :action="ApiUrl+'/article/write_page_pic_upload/'"

  >
    <el-icon><Plus /></el-icon>
  </el-upload>

  <el-dialog v-model="dialogVisible">
    <img w-full :src="dialogImageUrl" alt="Preview Image" />
  </el-dialog>

</template>

<script  setup>
import { ref ,reactive} from 'vue'
import {DeleteCarousel, GetSiteSettings} from "@/network/main";
import {ApiUrl} from "@/network/config";
import {ElMessage} from "element-plus";

const fileList = ref([])
const time=new Date().getTime()

const dialogImageUrl = ref('')
const dialogVisible = ref(false)
GetSiteSettings().then(res=>{
  fileList.value=res.data[0]['carousel']
})
const data=reactive({
  file_name:'',
})
const handleRemove = (file) => {
  data.file_name=file.url
  DeleteCarousel(data).then(res=>{
    if(res.data['code']===1){
      ElMessage.success(res.data['msg'])
    }
    else {
      ElMessage.error(res.data['msg'])
    }
  })
}
const handlePictureCardPreview = (file) => {
  dialogImageUrl.value = file.url+'?'+time
  dialogVisible.value = true
}
</script>
