<template>
<el-form style="max-width: 30%;margin: 0 auto;min-width: 300px">
  <el-form-item label="网站名称">
    <el-input v-model="data.site_name"></el-input>
  </el-form-item>
  <el-form-item>
    <el-button bg text type="warning" @click="onSubmit">提交</el-button>
  </el-form-item>
</el-form>
</template>

<script setup>
import {reactive, ref} from "vue";
import {GetSiteSettings, UpdateSiteSettings} from "@/network/main";
import {ElMessage} from "element-plus";

const data= reactive({
  site_name: ''
})
const id=ref(0)

GetSiteSettings().then(res=>{
  data.site_name=res.data[0]['site_name']
  id.value=res.data[0]['id']

})
const onSubmit = ()=>{
  UpdateSiteSettings(data,id.value).then(res=>{
    if(res.status===200){
      ElMessage.success('修改成功')
    }
    document.title = data.site_name;
  })
}
</script>

<style scoped>

</style>