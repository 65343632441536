<template>
  <el-col :xs="12" :sm="12" :md="6" v-for="o in items" :key="o.id" style="  text-align: center;padding: 10px">


      <el-card
          @click="$router.push('/article/'+o.id)"
          shadow="hover"
          class="card"
      >

        <p style="font-size: var(--el-font-size-extra-large);">
          <strong>
            {{o.title}}
          </strong></p>
        <div class="text item">{{ o.short_content}}</div>

      </el-card>

  </el-col>

</template>

<script setup>
import {getArticleList} from "@/network/article";
import {ref,defineExpose} from 'vue'

let items =ref([])
const  getArticles  = (isNew,page_info,Category)=> getArticleList(page_info,Category).then((res) => {
  if (res==0){
    return 0
  }
  if (isNew){
    items.value=[]
  }
  items.value=items.value.concat(res.data.results)

  return res.data.results.length
})
defineExpose({
  getArticles,
})

</script>

<style scoped>

</style>