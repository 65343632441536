import {request} from "@/network/request";

export function SubmitComment(data) {
    return request({
        url: "/comment/",
        method:"post",
        data: data
    })

}
export function GetComment(article_id) {
    return request({
        url: "/comment/",
        method:"get",
        params: {
            article_id: article_id
        }
    })

}