<template>
<el-image :src="picPath()"
          :zoom-rate="1.2"
          :preview-src-list="srcList"
          :initial-index="num"
          fit="cover"></el-image>
    <dv-decoration-6 style="width:300px;height:10px;" />

    <dv-decoration-12 style="width:150px;height:150px;" />

</template>

<script setup>
import { ref, onBeforeMount } from 'vue'
import mitts    from "@/composables/brotherCom";

const num=ref(0)
const srcList = [
    require('@/assets/bishe/采煤机.jpg'),
    require('@/assets/bishe/刮板机.jpg'),
    require('@/assets/bishe/液压支架.jpg'),
]
const picPath=()=>{
    if(num.value==0){
        return require('@/assets/bishe/采煤机.jpg')
    }else if(num.value==1){
        return require('@/assets/bishe/刮板机.jpg')
    }else if(num.value==2){
        return require('@/assets/bishe/液压支架.jpg')
    }
    return require('@/assets/bishe/刮板机.png')
}
mitts.on('change',(e)=>{
    num.value=e
})
onBeforeMount(()=>{
    mitts.on('change',(e)=>{
        num.value=e
    })
})
</script >

<style scoped>

</style>