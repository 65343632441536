<template>
  <div style="border: 1px solid #ccc;width: 100%">
    <Toolbar
        style="border-bottom: 1px solid #ccc"
        :editor="editorRef"
        :defaultConfig="toolbarConfig"
        :mode="mode"
    />
    <el-progress :text-inside="true"
                 :stroke-width="10" v-if="picProgress" :percentage="picProgress"  />

    <Editor
        style="height: 500px; overflow-y: hidden;"
        :defaultConfig="editorConfig"
        :mode="mode"
        v-model="valueHtml "
        @onCreated="handleCreated"
        @onChange="handleChange"
    />
  </div>

</template>
<script setup>
import '@wangeditor/editor/dist/css/style.css' // 引入 css

import { onBeforeUnmount,onBeforeUpdate, ref, shallowRef,defineProps,toRefs,defineEmits,defineExpose } from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import {ApiUrl} from "@/network/config";
import {ElMessage} from "element-plus";

// 编辑器实例，必须用 shallowRef
const editorRef = shallowRef()
const mode=ref('default')
const props=defineProps({
  content: {
    default: ''
  },
  words:{
    default: '0'
  },
  read_time:{
    default: '0'
  }
})

const content=toRefs(props).content
const valueHtml=ref(content.value)
const picProgress=ref(0)

onBeforeUpdate(() => {
  valueHtml.value=content.value
})

const emit = defineEmits(['update:content','update:words','update:read_time'])
const handleChange = () => {
  emit('update:content', valueHtml.value)
  emit('update:words', editorRef.value.getText().length)
  //阅读时长
  emit('update:read_time', Math.ceil(editorRef.value.getText().length/400))
}
const toolbarConfig = {}
const editorConfig = {
  placeholder: '<h1>请输入标题</h1>',
  MENU_CONF: {
    uploadImage: {
      server: ApiUrl+'/article/write_page_pic_upload/',
      headers: {
        Authorization: 'Bearer '+ localStorage.getItem('token')
      },
      onProgress(progress){
        picProgress.value=progress
      },
      onSuccess(file) {
        ElMessage({
          message: file.name+'上传成功',
          type: 'success',
        })
        picProgress.value=0

      },
      onFailed(file, res) {
        ElMessage({
          message: file.name+'上传失败'+res,
          type: 'error',
        })
      },
      onError(file, err) {
        ElMessage({
          message: file.name+'上传失败'+err,
          type: 'error',
        })
      }

    },
  },
  autofocus: true,
}

// 组件销毁时，也及时销毁编辑器
onBeforeUnmount(() => {
  const editor = editorRef.value
  if (editor == null) return
  editor.destroy()
})

const handleCreated = (editor) => {
  editorRef.value = editor // 记录 editor 实例，重要！
  setTimeout(() => {
    if (! valueHtml.value.replace('<p><br></p>','').length){
      editor.setHtml('<h1></h1>')
    }
  }, 1800)
}

const insertPic = (html) => {
  const editor = editorRef.value // 获取 editor ，必须等待它渲染完之后
  editor.focus()

  if (editor == null) return
  editor.dangerouslyInsertHtml(html)
  ElMessage.success("图片插入成功")

}
defineExpose({insertPic})
</script>