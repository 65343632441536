<template>
  <el-tabs v-model="activeName"  type="border-card"  :stretch="true" class="demo-tabs" >
    <el-tab-pane label="user" name="User" >
      <div>
      </div>

    </el-tab-pane>
    <el-tab-pane label="Config" name="second">Config</el-tab-pane>

    <el-tab-pane label="选股排行榜" name="third">
      <el-row justify="space-evenly">
      <el-col  span="12" >
        <h1>成功数</h1>
        <el-row v-for="(i,j) in rank" :key="i.name">
          <el-tag type="danger" effect="plain">{{j+1}}</el-tag>
        <el-text str size="large" style="flex: 1;margin-left: 10px">用户：{{i.user__username}}</el-text>
      <el-button style="margin-left: 20px "      type="warning"       link
      > 成功选股数：{{i.success_stocks}}</el-button>
        </el-row>
      </el-col>
      <el-col  span="12" >
        <h1>成功率</h1>

        <el-row v-for="(i,j) in rankrate" :key="i.name">
          <el-tag type="danger" effect="plain">{{j+1}}</el-tag>
          <el-text size="large" style="flex: 1;margin-left:10px">用户：{{i.user__username}}</el-text>
        <el-button  style="margin-left: 20px "      type="warning"       link

        > 成功率：<el-text v-if="i.success_rate"> {{i.success_rate+'%'}}</el-text>
        <el-text v-else>0%</el-text>
        </el-button>

        </el-row>
      </el-col>
      </el-row>
    </el-tab-pane>
  </el-tabs>

</template>

<script setup>
import {getChanlunBaseinfo} from "@/network/main";
import {ref} from "vue";
const rank=ref({})
const rankrate=ref({})
const activeName = 'third'
getChanlunBaseinfo().then(res=>{
  rank.value=res.data.rank
  rankrate.value=res.data.rank_rate
})
</script>

<style scoped>

.el-tabs__nav-scroll{
  width: 50%!important;
  margin: 0 auto!important;
}
</style>