<template>
  <div style="margin-top: 10px;">
    <el-select v-model="Category.value" clearable
               :size="'large'"
               style="width: 80px;"
               v-if="ShowOptions"
               placeholder="文章">
      <el-option
          v-for="item in Category"

          :key="item.value"
          :label="item.label"
          :value="item.value"

      />
    </el-select>
    <el-autocomplete
        v-model="input1"
        placeholder="Please Input"
        :class="{SearchShow:SearchShow==true}"
        :highlight-first-item="true"
        size="large"
        :fetch-suggestions="querySearchAsync"
        @select="SearchResultSelect"
        @focus="SearchShow=true;ShowOptions=true"
        @blur="SearchShow = false"
        :trigger-on-focus="false"
        value-key="title"
        clearable
    >
      <template #suffix>
        <el-icon  ><Search/></el-icon>
      </template>

    </el-autocomplete>
  </div>
</template>

<script setup>
import { ref } from "vue";
import {getArticleList} from "@/network/article";
import router from "@/router";
import {getCategoryList} from "@/network/main";
import {category_name_map} from "@/composables/category_name_map";
import {getGoodsList} from "@/network/goods";

const SearchShow = ref(false)
const input1 = ref('')
const Category = ref('article')
const ShowOptions = ref(false)
const querySearchAsync = (query, cb)=> {

    if(Category.value.value=='goods') {
    getGoodsList({search:query}).then(res=>{
      cb(res.data.results)
    })
    }
  // 初始状态搜索默认文章
    else {
    getArticleList({search: query,size:100}).then(res => {
      cb(res.data.results)
    })
  }
  ShowOptions.value=false
}
getCategoryList().then((res) => {
  Category.value=Object.keys(res.data).map((item)=>{
    return {
      value:item,
      label:category_name_map.value[item]
    }
  })
})
const SearchResultSelect = (item)=>{
  SearchShow.value = true
  if(Category.value.value=='goods'){
    router.push('/'+Category.value.value+'/'+item.id)
  }
  else {
    router.push('/article/'+item.id)
  }
  ShowOptions.value=false
}

</script>

<style >
@media screen and (max-width: 768px) {
  .head_search {
    width: 60px;

  }
  .SearchShow{
    margin-left: 10px;
    width: 150px;
  }

}
@media screen and (min-width: 768px) {

  .SearchShow {
    margin-left: 10px;
    width: 500px;
  }
}
</style>