<template>

<div style="height: 500px;">

  <el-row justify="center">

    <Base_Fliter @item_class="item_class"/>

  </el-row>
  <el-divider>
    {{ Category }}

  </el-divider>

  <el-row   :gutter="30"
            v-infinite-scroll="load"
            :infinite-scroll-disabled="disabled"
            infinite-scroll-distance="40"

      >

    <Articles_Item   ref="Articles_item"/>

  </el-row>

  <p v-if="loading" v-loading="loading" element-loading-text="Loading..." >加载中</p>

  <p v-if="noMore" style="text-align: center">到底啦！</p>

</div>
</template>

<script setup>
import Base_Fliter from "@/components/Base_Fliter";
import Articles_Item from "@/components/article/articlelist/Articles_Item";
import {  ref,computed  } from 'vue'
import { page_info} from "@/composables/pagination";
import { ElMessage } from 'element-plus'

//内容动态加载
let loading = ref(false)
let Articles_item = ref(null)
let noMore = ref(false)
const isNew=ref(false)
const disabled = computed(() => loading.value || noMore.value)
const load = () => {
  loading.value = true
  setTimeout(() => {
    Articles_item.value.getArticles(isNew.value,page_info,Category.value).then((data)=>{
        page_info.page += 1
        loading.value = false
        isNew.value=false
      if (!data) {
        noMore.value = true
        page_info.page=1
        ElMessage({
          message: '到底啦！',
          type: 'warning',
        })
      }
    })
  }, 500)

}

//分割横线分类名称
const Category=ref('全部')

const item_class=(val)=>{
  page_info.page=1
  noMore.value=false
  Category.value=val
  isNew.value=true
  load()

}
</script>

<style scoped>

</style>